import React from "react";
import styles from "./footer.module.css";
import { Link } from "react-router-dom";
import Logo from "../../images/logo1.jpg";
import facebook from "../../images/socialicons/icons8-facebook.svg";
import instagram from "../../images/socialicons/icons8-instagram.svg";
import twitter from "../../images/socialicons/icons8-twitter.svg";
import linkedn from "../../images/socialicons/icons8-linkedin.svg";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div>
        <img
          alt="logo"
          src={Logo}
          className={styles.logo}
          //
        />
      </div>

      <div className={styles.footerCard}>
        <ul>
          <li>
            <Link to={"/about-us"}>About Us</Link>
          </li>
          <li>
            <Link to={"/country/spain"}>Spain</Link>
          </li>
          <li>
            <Link to={"/country/indonesia"}>Indonesia</Link>
          </li>
          <li>
            <Link to={"/country/canada"}>Canada</Link>
          </li>
          <li>
            <Link to={"/country/greece"}>Greece</Link>
          </li>
          <li>
            <Link to={"/country/ireland"}>Ireland </Link>
          </li>
          <li>
            <Link to={"/Blogs"}>Blogs</Link>
          </li>
          <li>
            <Link to={"/termsandconditions"}>Terms of use</Link>
          </li>
          <li>
            <Link to={"/privacy"}>Privacy Policy</Link>
          </li>
        </ul>
      </div>
      <div className={styles.footerCard}>
        <div className={styles.footerSocial}>
          <Link to="/#contacts" target="_blank" className={styles.link}>
            <img src={facebook} alt="facebook" className={styles.social} />{" "}
          </Link>
          <Link to="/#contacts" target="_blank" className={styles.link}>
            <img className={styles.social} src={instagram} alt="instagram" />{" "}
          </Link>

          <Link
            to="/#contacts"
            target="_blank"
            aria-label="twitter"
            className={styles.link}
          >
            <img src={twitter} alt="twitter" className={styles.social} />
          </Link>

          <Link to="/#contacts" target="_blank" className={styles.link}>
            <img src={linkedn} alt="linedin" className={styles.social} />{" "}
          </Link>
        </div>
      </div>
      <div>
        <p>© 2024. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
