"use client"
import React, { useEffect, useState } from "react";
import styles from "../Terms/terms.module.css";

const PrivacyPolicy = () => {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const dateString = today.toLocaleDateString();
    setCurrentDate(dateString);
  }, []);
  return (
    <div className={styles.terms}>
      {" "}
      <h1> INTRODUCTION </h1>
      <p>
        <strong>Last Updated: {currentDate}</strong>
      </p>
      <p>
        This Privacy Policy (“Policy”) explains how{" "}
        <a href="">www.findHotelToday.com</a> (the "Website") and any mobile
        applications that we may issue (the “Apps”) collect and process Personal
        Information.
        <a />
      </p>
      <h1>Protection of Personal Data and Privacy Charter</h1>
      <p>
        We are committed to finding satisfactory solutions to ensure compliance
        with applicable regulations. If issues persist, you may lodge a
        complaint with the relevant supervisory authority.
      </p>
      <h2>WE RESPECT THE PRIVACY OF EVERY INDIVIDUAL WHO VISITS THIS SITE</h2>
      <p>
        When using the website{" "}
        <a href="https://www.findHotelToday.com">https://www.findHotelToday.com</a>{" "}
        (the "Service(s)"), we ("findHotelToday", "We", "Our") may collect and
        process your personal data.
      </p>
      <p>
        The purpose of this Protection of Personal Data and Privacy Charter (the
        "Charter") is to inform users of the Services ("User(s)", "You", "Your")
        of the means used to:
      </p>
      <ul>
        <li>
          Collect and process Your personal data, in compliance with applicable
          Irish and European legislation, including the General Data Protection
          Regulation (GDPR) and the ePrivacy Directive.
        </li>
        <li>
          View and retain information about Your browsing on the Services that
          may be saved in "cookie" files.
        </li>
      </ul>
      <p>
        We take your privacy seriously and are committed to protecting your
        personal data.
      </p>
      <p>
        By accessing and/or using the Services, You agree that Your personal
        data will be collected and processed under the terms and conditions set
        forth below. If You do not agree to this Policy, You should cease all
        use of the Services.
      </p>
      <p>
        The Charter is an integral part of the Terms of Service ("TOS") and must
        be read together with such TOS.
      </p>
      <p>
        We may amend the Charter to comply with any legislative, regulatory,
        jurisprudential, editorial, or technical changes under the conditions
        described in the TOS. Therefore, before browsing, You should refer to
        the latest version of the Charter.
      </p>
      <h2>1. Definitions</h2>
      <p>
        The terms "personal data" ("Personal Data"), "process/processing", "data
        controller", "processor", "recipient(s)", "consent", and "filing system"
        have the same meaning as in Article 4 of the GDPR.
      </p>
      <h2>2. Data Controller</h2>
      <p>
        The controller of Users' Personal Data collected on the Services is
        findHotelToday, a limited liability company, registered in Ireland, with
        the contact details provided in the "Contact Information" section.
      </p>
      <h2>3. What Personal Data is Collected?</h2>
      <p>
        During the collection of Personal Data, the User will be informed if
        certain data must be provided or if they are optional. If required data
        is not provided, access to certain features of the Services may be
        limited.
      </p>
      <p>findHotelToday may collect the following categories of Personal Data:</p>
      <ul>
        <li>
          <strong>Identity:</strong> Name, nationality, email address, postal
          address, date of birth, and other relevant contact details.
        </li>
        <li>
          <strong>Technical Data:</strong> IP address, browser type, cookies,
          and other technical data related to the use of the Services.
        </li>
      </ul>
      <h2>WHO IS COLLECTING YOUR INFORMATION</h2>
      <p>
      The Website is owned and operated by The Booking Hotel (Ireland),
        Inc. ("The Booking Hotel", “we”, “us” or “our”). This Privacy Policy applies
        only to the Website and not to third-party booking engines or
        independently owned The Booking Hotel.
      </p>
      <h2>WHAT INFORMATION WE COLLECT</h2>
      <p>
        Personal Information is gathered in several ways, both indirectly (e.g.,
        domain name, IP address, browser type, page views, mobile device ID, and
        geolocation) and directly (e.g., name, address, email, phone number,
        marital status, and business information). We collect this information
        for various purposes, including providing functionality, customizing the
        Website, and managing content.
      </p>
      <h2>RIGHT NOT TO PROVIDE INFORMATION</h2>
      <p>
        In certain jurisdictions, you may have the right not to provide Personal
        Information. However, not providing such information may limit your
        ability to use certain services on the Website, such as making
        reservations online.
      </p>
      <h3>4. Why Do We Collect Your Personal Data?</h3>
      <p>
        Your Personal Data is collected and processed for the following
        purposes:
      </p>
      <ul>
        <li>
          To provide and manage the Services, including booking hotels in
          Dublin.
        </li>
        <li>To improve and personalize your experience on the Services.</li>
        <li>To manage customer relationships and support.</li>
        <li>To send you offers and news, with your consent.</li>
      </ul>
      <h2>5. Who Are the Recipients of Your Personal Data?</h2>
      <p>
        The database of Personal Data is confidential. findHotelToday will take
        necessary precautions to preserve its security and prevent unauthorized
        access.
      </p>
      <p>Personal Data may be shared with:</p>
      <ul>
        <li>
          <strong>Authorities and Public Bodies:</strong> As required by law.
        </li>
        <li>
          <strong>Third Parties:</strong> Such as business partners, including
          hotels, for managing reservations.
        </li>
      </ul>
      <h2>6. Your Rights Regarding Your Personal Data</h2>
      <p>
        You have the right to access, correct, delete, and object to the
        processing of your Personal Data. You may also request data portability
        and withdraw your consent at any time.
      </p>
      <h2>7. Retention Period of Your Personal Data</h2>
      <p>
        Your Personal Data will not be retained longer than necessary for the
        purposes for which it was collected, in accordance with applicable
        regulations.
      </p>
      <h2>8. Protection of the Personal Data of Minors</h2>
      <p>
        The Services are not intended for minors under the age of 15. We do not
        knowingly collect data from children under 15 without parental consent.
      </p>
      <h2>9. Security</h2>
      <p>
        We implement appropriate technical and organizational measures to ensure
        the security of your Personal Data.
      </p>
      <h2>10. Cookie Policy</h2>
      <p>
        When using the Services, navigation information relating to Your
        computer, tablet, smartphone, etc. ("Terminal") may be stored in text
        files called "cookies" installed on your Terminal, subject to the
        choices you have expressed about "cookies" and which You can change at
        any time.
      </p>
      <h3>10.1 What Are Cookies?</h3>
      <p>
        A "cookie" is a small text, image, or software file that We may record
        on the hard disk of Your Terminal through your Internet browser when You
        use the Services, subject to Your agreement.
      </p>
      <p>
        Cookies serve various purposes, such as improving navigation, securing
        your connection, or adapting the content of a page to your interests or
        usage preferences.
      </p>
      <h3>10.2 What Are the "Cookies" on the Services Used For?</h3>
      <p>
        "Cookies" are used for the purposes described below, subject to Your
        choices that You may express and modify at any time via the settings of
        the browser software used when browsing the Services.
      </p>
      <h4>10.2.1 Navigation "Cookies"</h4>
      <p>
        Navigation "cookies" improve the performance of the Services and do not
        require the informing and the prior consent of the User.
      </p>
      <p>These cookies make it possible:</p>
      <ul>
        <li>
          To adapt the presentation of the Services to the display preferences
          of Your Terminal.
        </li>
        <li>
          To allow access to a reserved area subject to username and password.
        </li>
        <li>
          To save information about the forms you completed when using the
          Services.
        </li>
        <li>
          To save Your usage preferences, display settings, and readers you use.
        </li>
        <li>
          To adapt the informational or promotional content of the Services.
        </li>
        <li>To implement security measures.</li>
      </ul>
      <h4>10.2.2 Statistical "Cookies"</h4>
      <p>
        The statistical "cookies" issued on the Services make it possible to
        establish statistics and measures of visit frequency and use the various
        elements composing the Services. The results are processed anonymously
        for exclusively statistical purposes.
      </p>
      <h2>DO NOT TRACK SIGNALS</h2>
      <p>
        Our systems do not currently recognize browser “do-not-track” requests.
        You may disable certain tracking by disabling cookies.
      </p>
      <h2>CLEAR GIFS, PIXEL TAGS, AND OTHER TECHNOLOGIES</h2>
      <p>
        We may use clear GIFs (a.k.a. web beacons, web bugs, or pixel tags) to
        track activities on the Website and compile statistics. These
        technologies may also be used in HTML emails to track response rates and
        identify when emails are viewed.
      </p>
      <h2>THIRD-PARTY ANALYTICS/TRACKING</h2>
      <p>
        We use automated devices and applications, such as Google Analytics, to
        evaluate Website usage. These tools help us improve services,
        performance, and user experiences. These entities may use cookies and
        other tracking technologies.
      </p>
      <h4>10.2.3 Advertising "Cookies" and Third Party "Cookies"</h4>
      <p>
        Advertising and third party "cookies" are primarily used to personalize
        Your browsing experience and to monitor the performance of advertising
        campaigns. These may also be used to prevent fraudulent activities.
      </p>
      <h3>10.3 Period of Validity of "Cookies"</h3>
      <p>
        The "cookies" issued on the Services are session "cookies" (limited to
        the time of a connection) and persistent "cookies" (retained beyond the
        session). Session cookies are deleted when you close your browser, while
        persistent "cookies" remain stored for a specified period.
      </p>
      <h3>10.4 Your Choices Regarding "Cookies"</h3>
      <p>
        You can manage your cookie preferences through your browser settings.
        You can accept, refuse, or delete cookies. Note that disabling cookies
        may affect your experience on the Services.
      </p>
      <p>
        For more information, you can visit the help menu or the dedicated
        section of your browser.
      </p>
      <h2>11. Contact Information</h2>
      <p>
        For any questions or concerns regarding your Personal Data, you can
        contact us using the form on our website.      </p>
     
    </div>
  );
};

export default PrivacyPolicy;
