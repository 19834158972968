"use client";
import React, { useState } from "react";
import styles from "./contact.module.css";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import banner from "../../images/country/contact.webp";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [notification, setNotification] = useState(""); // Bildirim state'i

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Formun tüm alanlarının doldurulup doldurulmadığını kontrol et
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      setNotification("Lütfen tüm alanları doldurun.");
      return;
    }

    // Bildirimi başarı mesajı olarak güncelle
    setNotification("Mesajınız başarıyla gönderildi!");

    // Form verilerini burada işleyebilirsin
    console.log(formData);

    // Formu sıfırlama (isteğe bağlı)
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <div className={styles.banner}>
        <img src={banner} alt="Contact Banner" />
        <div className={styles.text}>
          <h2>Contact Us</h2>
        </div>
      </div>

      <div className={styles.contactSection}>
        <div className={styles.infoRow}>
          <ul>
            <li>
              <FaMapMarkerAlt />
              <span>Amiens Street Dublin 1, Dublin, Ireland</span>
            </li>
            <li>
              <FaPhoneAlt />
              <span>+(353) 1 874 2837</span>
            </li>
            <li>
              <FaEnvelope />
              <span>support@findHotelToday.com</span>
            </li>
          </ul>
        </div>

        <div className={styles.formRow}>
          <div className={styles.inputCol}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              required
            />
          </div>
          <div className={styles.inputCol}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className={styles.inputCol}>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              required
            />
          </div>
        </div>

        <div className={styles.messageRow}>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
            required
          />
          <div className={styles.button}>
            <button type="submit" onClick={handleSubmit}>
              Send Message
            </button>
          </div>
        </div>

        {/* Bildirimi göster */}
        {notification && <div className={styles.notification}>{notification}</div>}
      </div>
    </>
  );
};

export default SignUp;
