"use client"
import React, { useState } from 'react';
import styles from './faq.module.css';
import { RiAddLine, RiSubtractFill } from 'react-icons/ri'; // Import icons




const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader} onClick={() => setIsOpen(!isOpen)}>
        <h2 className={styles.accordionQuestion}>{question}</h2>
        <span className={styles.accordionIcon}>
        {isOpen ? <RiSubtractFill /> : <RiAddLine />} {/* Render icon conditionally */}
        </span>
      </div>
      <div className={styles.accordionContent} style={{ height: isOpen ? 'auto' : '0px' }}>
        <p className={styles.accordionAnswer}>{answer}</p>
      </div>
    </div>
  );
};

const Faq = () => {
  const faqData = [
    {
      question: ' How do I search for a hotel?',
      answer: 'To search for a hotel, simply enter your destination, travel dates, and the number of guests in the search bar. You will be provided with a list of available hotels that match your criteria.',
    },
    {
      question: 'Can I cancel or modify my booking? ',
      answer: 'Cancellation and modification policies vary by hotel. Please check the cancellation policy listed on your booking confirmation email or the hotels details page before making any changes   ',
    },
    {
      question: 'What should I do if I have a problem with my booking?',
      answer: 'If you encounter any issues with your booking, please contact our customer service team through the "Contact Us" page. We are available 24/7 to assist you.',
    },
    {
      question: 'Are there any hidden fees when booking a hotel?',
      answer: 'No, we believe in transparent pricing. The total cost of your stay, including taxes and fees, will be clearly displayed before you confirm your booking. ',
    },
    {
      question: 'Can I leave a review after my stay?',
      answer: 'Yes, after your stay, we encourage you to leave a review to help other travelers make informed decisions. You’ll receive an email invitation to review the hotel. ',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.accordionWrapper}>
        <h1 className={styles.accordionTitle}>Frequently Asked Questions</h1>
        {faqData.map((faq, index) => (
          <AccordionItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
