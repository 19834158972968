import React from "react";
import styles from "./hotel.module.scss";

export default function HotelSection({ hotels , country, }) {
  return (
    <section className={styles.hotelSection}>
      <h2 className={styles.sectionTitle}>TOP HOTELS IN {country.toUpperCase() || "Country"}</h2>
      <div className={styles.hotelList}>
        {hotels.map((hotel) => (
          <article key={hotel.id} className={styles.hotelCard}>
            <img
              src={hotel.coverImage}
              alt={hotel.name}
              className={styles.hotelImage}
            />
            <div className={styles.hotelInfo}>
              <h3 className={styles.hotelName}>{hotel.name}</h3>
              <p className={styles.hotelDescription}>{hotel.description}</p>
              <div className={styles.button}>  <a
                href={hotel.link}
                className={styles.hotelLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Book Now
              </a></div>
            
            </div>
          </article>
        ))}
      </div>
    </section>
  );
}
