import spain from "../../../images/country/spain.webp";
import canada from "../../../images/country/canada.webp";
import indonesia from "../../../images/country/indonesia.webp";
import ireland from "../../../images/country/ireland.webp";
import greece from "../../../images/country/greece.webp";

import spain0 from "../../../images/country/spain01.webp";
import spain1 from '../../../images/country/spain02.webp'
import spain2 from '../../../images/country/spain03.webp'
import spain3 from '../../../images/country/spain04.webp'
import spain4 from '../../../images/country/spain05.webp'


import canada0 from "../../../images/country/canada01.webp";
import canada1 from '../../../images/country/canada02.webp'
import canada2 from '../../../images/country/canada03.webp'
import canada3 from '../../../images/country/canada04.webp'
import canada4 from '../../../images/country/canada05.webp'


import greece0 from "../../../images/country/greece01.webp";
import greece1 from '../../../images/country/greece02.webp'
import greece2 from '../../../images/country/greece03.webp'
import greece3 from '../../../images/country/greece04.webp'
import greece4 from '../../../images/country/greece05.webp'

import indonesia0 from "../../../images/country/indonesia01.webp";
import indonesia1 from '../../../images/country/indonesia02.webp'
import indonesia2 from '../../../images/country/indonesia03.webp'
import indonesia3 from '../../../images/country/indonesia04.webp'
import indonesia4 from '../../../images/country/indonesia05.webp'

import ireland0 from "../../../images/country/ireland01.webp";
import ireland1 from '../../../images/country/ireland02.webp'
import ireland2 from '../../../images/country/ireland03.webp'
import ireland3 from '../../../images/country/ireland04.webp'
import ireland4 from '../../../images/country/ireland05.webp'

export const countryData = {
  spain: {
    title: "Spain",
    description:
      "Spain is known for its rich culture and beautiful architecture.",
    image: ` ${spain}`,
    text: `
    <h2> Spain is a top destination for travelers, known for its rich history, diverse culture, stunning landscapes, and vibrant cities. </h2> Here's an overview to help any traveler explore and appreciate the best Spain has to offer:
      <ul>
        <li><strong>Major Cities to Visit:</strong>
          <ul>
            <li><strong>Madrid:</strong> The capital city, known for its royal heritage, world-class museums like the Prado and Reina Sofía, and bustling nightlife. Visitors can explore the Royal Palace, Puerta del Sol, and Retiro Park.</li>
            <li><strong>Barcelona:</strong> Famous for its unique architecture by Antoni Gaudí, including the iconic Sagrada Família and Park Güell. It also boasts a beautiful coastline and the lively Las Ramblas street.</li>
            <li><strong>Seville:</strong> The heart of Andalusian culture, offering Flamenco music, the stunning Alcázar palace, and the impressive Seville Cathedral with its Giralda tower.</li>
            <li><strong>Valencia:</strong> Known for its futuristic architecture, like the City of Arts and Sciences, and its status as the birthplace of paella, Spain’s famous rice dish.</li>
            <li><strong>Granada:</strong> A picturesque city set against the Sierra Nevada mountains, home to the magnificent Alhambra, a testament to Spain's Moorish past.</li>
          </ul>
        </li>

        <li><strong>Cultural Highlights:</strong>
          <ul>
            <li><strong>Spanish Festivals:</strong> Spain is famous for its festivals, like La Tomatina (a massive tomato fight in Buñol), Las Fallas (a celebration of art and fire in Valencia), and the Running of the Bulls in Pamplona.</li>
            <li><strong>Flamenco:</strong> This passionate dance and music style, originating in Andalusia, is a must-experience for visitors, especially in cities like Seville and Granada.</li>
            <li><strong>Art and Architecture:</strong> Spain has a rich artistic heritage, with famous artists like Picasso, Dalí, and Velázquez. Don’t miss out on the architectural wonders of Gaudí in Barcelona or the Moorish influences in southern Spain.</li>
          </ul>
        </li>

        <li><strong>Cuisine:</strong>
          <ul>
            <li><strong>Tapas:</strong> Small dishes that are perfect for sharing, ranging from patatas bravas (fried potatoes with a spicy sauce) to jamón ibérico (cured ham) and croquetas.</li>
            <li><strong>Paella:</strong> A must-try, especially in Valencia, the traditional dish is made with rice, seafood, chicken, or rabbit.</li>
            <li><strong>Churros con Chocolate:</strong> A popular Spanish snack, especially in Madrid, churros are fried dough pastries served with thick hot chocolate for dipping.</li>
            <li><strong>Wines and Sangria:</strong> Spain is one of the world’s top wine producers, famous for its red wines (Rioja and Ribera del Duero) and sparkling cava. Sangria, a mix of wine and fruits, is a refreshing drink enjoyed by many travelers.</li>
          </ul>
        </li>

        <li><strong>Natural Landscapes:</strong>
          <ul>
            <li><strong>Beaches:</strong> Spain has some of Europe’s best beaches, including the Costa del Sol, Costa Brava, and the Balearic Islands (Mallorca, Menorca, Ibiza).</li>
            <li><strong>Mountains:</strong> For outdoor enthusiasts, the Pyrenees and Sierra Nevada offer excellent opportunities for hiking, skiing, and adventure sports.</li>
            <li><strong>National Parks:</strong> Spain is home to a variety of natural parks, including Picos de Europa in the north and Teide National Park in the Canary Islands, home to Spain’s highest peak.</li>
          </ul>
        </li>

        <li><strong>Historical Sites:</strong>
          <ul>
            <li><strong>The Alhambra (Granada):</strong> A stunning example of Moorish architecture and design, this palace and fortress complex is a UNESCO World Heritage site.</li>
            <li><strong>La Sagrada Família (Barcelona):</strong> An unfinished masterpiece by Gaudí, this basilica is a symbol of Barcelona and a marvel of modern architecture.</li>
            <li><strong>The Roman Aqueduct (Segovia):</strong> One of the best-preserved Roman aqueducts in the world, it stands as a testament to ancient engineering.</li>
            <li><strong>The Camino de Santiago:</strong> A pilgrimage route that leads to the city of Santiago de Compostela, where travelers can visit the famous cathedral and enjoy the unique spiritual and cultural experience along the way.</li>
          </ul>
        </li>

        <li><strong>Travel Tips:</strong>
          <ul>
            <li><strong>Public Transportation:</strong> Spain has an excellent transportation system, including high-speed trains (AVE) connecting major cities like Madrid, Barcelona, and Seville.</li>
            <li><strong>Language:</strong> While Spanish (Castellano) is the official language, travelers might hear regional languages like Catalan (in Barcelona), Basque (in Bilbao), and Galician (in Santiago de Compostela).</li>
            <li><strong>Best Time to Visit:</strong> The best times to visit are in the spring (April to June) and fall (September to October) when the weather is mild and the crowds are thinner.</li>
            <li><strong>Local Etiquette:</strong> Spaniards tend to eat late, with lunch often served from 2 to 4 PM and dinner starting around 9 PM or later. It's also common to take a siesta (afternoon break) in smaller towns.</li>
          </ul>
        </li>
      </ul>
    `,
  },

  canada: {
    title: " Canada",
    description:
      "Canada is famous for its stunning landscapes and cold winters.",
    image: ` ${canada}`,
    text: `
    <h2>  Canada is a vast and diverse country that offers a wide range of experiences for travelers, from stunning natural landscapes to vibrant cities and rich cultural heritage.</h2> Here’s an extensive guide for anyone planning to visit Canada:
      <ul>
        <li><strong>Major Cities to Visit:</strong>
          <ul>
            <li><strong>Toronto:</strong> As Canada’s largest city, Toronto is a dynamic metropolis with a vibrant cultural scene. Key attractions include the CN Tower, Royal Ontario Museum, and the Distillery District. It's also a great starting point for trips to Niagara Falls.</li>
            <li><strong>Vancouver:</strong> Located on the west coast, Vancouver is known for its breathtaking scenery, including mountains, ocean views, and lush forests. Highlights include Stanley Park, Granville Island, and Capilano Suspension Bridge.</li>
            <li><strong>Montreal:</strong> A city rich in French influence, Montreal offers a European flair with its cobblestone streets in Old Montreal, beautiful Notre-Dame Basilica, and lively festivals such as the Montreal Jazz Festival.</li>
            <li><strong>Ottawa:</strong> The capital of Canada, Ottawa is home to important national landmarks like Parliament Hill, the National Gallery of Canada, and the Rideau Canal, which becomes the world’s largest skating rink in winter.</li>
            <li><strong>Quebec City:</strong> Known for its old-world charm and well-preserved historic architecture, Quebec City’s highlights include Château Frontenac, the fortified Old City (a UNESCO World Heritage site), and Montmorency Falls.</li>
            <li><strong>Calgary:</strong> Famous for the Calgary Stampede, a large annual rodeo and festival, the city is also a gateway to the Canadian Rockies and Banff National Park.</li>
            <li><strong>Halifax:</strong> The largest city in the Maritimes, Halifax offers beautiful coastal views, historical landmarks like the Halifax Citadel, and a lively waterfront area.</li>
          </ul>
        </li>

        <li><strong>Cultural Highlights:</strong>
          <ul>
            <li><strong>Festivals:</strong> Canada hosts a range of vibrant festivals, including the Toronto International Film Festival (TIFF), Montreal’s Just for Laughs comedy festival, and Quebec City’s Winter Carnival.</li>
            <li><strong>Indigenous Culture:</strong> Learn about Canada’s Indigenous peoples by visiting places like the Canadian Museum of History in Ottawa or taking part in cultural tours in regions such as British Columbia or the Yukon.</li>
            <li><strong>Art and Museums:</strong> Canada’s cities are home to numerous art galleries and museums, such as the Art Gallery of Ontario in Toronto, the Museum of Anthropology in Vancouver, and the Montreal Museum of Fine Arts.</li>
          </ul>
        </li>

        <li><strong>Natural Wonders:</strong>
          <ul>
            <li><strong>Banff National Park:</strong> Located in Alberta, Banff is famous for its stunning mountain scenery, turquoise lakes like Lake Louise and Moraine Lake, and abundant wildlife.</li>
            <li><strong>Jasper National Park:</strong> Another jewel in the Canadian Rockies, Jasper offers dramatic landscapes, including glaciers, waterfalls, and scenic trails.</li>
            <li><strong>Niagara Falls:</strong> One of the most famous waterfalls in the world, Niagara Falls straddles the border between Canada and the United States.</li>
            <li><strong>Prince Edward Island:</strong> Known for its rolling hills, red sand beaches, and as the home of "Anne of Green Gables."</li>
            <li><strong>The Northern Lights:</strong> In northern parts of Canada, particularly in Yukon, the Northwest Territories, and Nunavut, travelers can witness the spectacular aurora borealis (Northern Lights).</li>
          </ul>
        </li>

        <li><strong>Adventure and Outdoor Activities:</strong>
          <ul>
            <li><strong>Hiking and Camping:</strong> Canada offers endless hiking opportunities, from coastal trails in Nova Scotia to mountain treks in the Rockies.</li>
            <li><strong>Skiing and Snowboarding:</strong> Resorts like Whistler Blackcomb in British Columbia and Mont-Tremblant in Quebec are famous for skiing and snowboarding.</li>
            <li><strong>Kayaking and Canoeing:</strong> Explore Canada's waterways by canoe or kayak, such as Algonquin Park in Ontario and the Bowron Lakes in British Columbia.</li>
            <li><strong>Wildlife Viewing:</strong> Canada's vast wilderness is home to incredible wildlife, from whales and bison to polar bears in Churchill, Manitoba.</li>
          </ul>
        </li>

        <li><strong>Canadian Cuisine:</strong>
          <ul>
            <li><strong>Poutine:</strong> A must-try dish consisting of French fries topped with cheese curds and gravy, originating from Quebec.</li>
            <li><strong>Maple Syrup:</strong> A symbol of Canada, maple syrup is commonly enjoyed on pancakes or in desserts.</li>
            <li><strong>Butter Tarts:</strong> A traditional Canadian pastry with a sweet filling made from butter, sugar, and eggs.</li>
            <li><strong>Tourtière:</strong> A savory meat pie popular in Quebec during the holiday season.</li>
            <li><strong>Salmon:</strong> Particularly famous in British Columbia, Canadian salmon is enjoyed grilled, smoked, or in sushi.</li>
          </ul>
        </li>

        <li><strong>Travel Tips:</strong>
          <ul>
            <li><strong>Public Transportation:</strong> Canada’s major cities offer efficient public transportation systems, including buses, streetcars, and subways in cities like Toronto, Montreal, and Vancouver.</li>
            <li><strong>Language:</strong> Canada has two official languages—English and French.</li>
            <li><strong>Currency:</strong> The local currency is the Canadian dollar (CAD).</li>
            <li><strong>Best Time to Visit:</strong> Summer is ideal for hiking and exploring, while winter is perfect for skiing and snowboarding.</li>
            <li><strong>Safety:</strong> Canada is considered one of the safest countries for travelers, but precautions should still be taken.</li>
          </ul>
        </li>
      </ul>
    `,
  },

  indonesia: {
    title: " Indonesia",
    description:
      "Indonesia is a captivating destination with its diverse culture, stunning landscapes, and rich history.",
    image: ` ${indonesia}`,
    text: `
   <h2>   Indonesia is a captivating destination with its diverse culture, stunning landscapes, and rich history. </h2>Here’s a comprehensive guide for travelers:

      <ul>
        <li><strong>Major Destinations:</strong>
          <ul>
            <li><strong>Bali:</strong> Known for its beautiful beaches, vibrant nightlife, and rich culture. Key attractions include Uluwatu Temple, Tanah Lot, and the rice terraces of Ubud.</li>
            <li><strong>Jakarta:</strong> The bustling capital city offers a mix of modern skyscrapers and traditional markets. Visit the National Monument (Monas), the Old Town (Kota Tua), and the Jakarta History Museum.</li>
            <li><strong>Yogyakarta:</strong> A cultural hub famous for its traditional arts and crafts. Nearby, you can explore the majestic Borobudur Temple and Prambanan Temple, both UNESCO World Heritage sites.</li>
            <li><strong>Lombok:</strong> Offers beautiful beaches, waterfalls, and the majestic Mount Rinjani. It’s less touristy than Bali and provides a more serene experience.</li>
            <li><strong>Sumatra:</strong> Known for its incredible wildlife, including orangutans in Bukit Lawang, and stunning landscapes like Lake Toba and the Sumatran rainforest.</li>
            <li><strong>Komodo Island:</strong> Famous for the Komodo dragons, one of the world’s largest lizards. The island is also known for its beautiful marine life and diving spots.</li>
          </ul>
        </li>

        <li><strong>Cultural Highlights:</strong>
          <ul>
            <li><strong>Traditional Arts:</strong> Indonesia has a rich tradition of music, dance, and crafts. Watch a traditional Balinese dance performance or enjoy the Gamelan music in Yogyakarta.</li>
            <li><strong>Festivals:</strong> Experience vibrant festivals like Nyepi (Balinese Day of Silence), the Jakarta International Java Jazz Festival, and the Waisak Festival at Borobudur Temple.</li>
            <li><strong>Local Markets:</strong> Visit traditional markets like Ubud Art Market in Bali, Pasar Baru in Jakarta, and Malioboro Street in Yogyakarta for local crafts, foods, and souvenirs.</li>
          </ul>
        </li>

        <li><strong>Cuisine:</strong>
          <ul>
            <li><strong>Nasi Goreng:</strong> A flavorful fried rice dish commonly served with vegetables, meats, and sometimes a fried egg on top.</li>
            <li><strong>Satay:</strong> Skewered and grilled meat served with a rich peanut sauce.</li>
            <li><strong>Rendang:</strong> A spicy beef stew cooked in coconut milk, originating from the Minangkabau ethnic group in West Sumatra.</li>
            <li><strong>Gado-Gado:</strong> A salad made with boiled vegetables, tofu, tempeh, and a savory peanut sauce.</li>
            <li><strong>Soto:</strong> A traditional soup made with meat (chicken or beef), vegetables, and spices, often served with rice or noodles.</li>
          </ul>
        </li>

        <li><strong>Natural Wonders:</strong>
          <ul>
            <li><strong>Mount Bromo:</strong> An active volcano in East Java offering spectacular sunrise views and volcanic landscapes.</li>
            <li><strong>Raja Ampat:</strong> A remote archipelago in Papua known for its stunning marine biodiversity and diving opportunities.</li>
            <li><strong>Baliem Valley:</strong> Located in Papua, this area is known for its indigenous tribes, beautiful landscapes, and unique cultural experiences.</li>
            <li><strong>Tana Toraja:</strong> Famous for its elaborate funeral ceremonies and traditional Torajan architecture.</li>
          </ul>
        </li>

        <li><strong>Adventure and Activities:</strong>
          <ul>
            <li><strong>Diving and Snorkeling:</strong> Explore vibrant coral reefs in Raja Ampat, Komodo National Park, and Bunaken Marine Park.</li>
            <li><strong>Surfing:</strong> Bali is a popular surfing destination, with great waves for all skill levels, particularly in Kuta and Uluwatu.</li>
            <li><strong>Hiking:</strong> Trek to the top of Mount Rinjani on Lombok for breathtaking views or explore the trails around Mount Bromo.</li>
            <li><strong>Wildlife Watching:</strong> Visit national parks like Bukit Lawang and Gunung Leuser to see orangutans and other wildlife.</li>
          </ul>
        </li>

        <li><strong>Travel Tips:</strong>
          <ul>
            <li><strong>Language:</strong> The official language is Indonesian (Bahasa Indonesia), but English is widely spoken in tourist areas.</li>
            <li><strong>Currency:</strong> The local currency is the Indonesian Rupiah (IDR). ATMs are common, and credit cards are widely accepted in urban areas.</li>
            <li><strong>Climate:</strong> Indonesia has a tropical climate with two main seasons—wet (November to March) and dry (April to October).</li>
            <li><strong>Health and Safety:</strong> Be cautious of food and water hygiene, and consider getting vaccinations for diseases like typhoid and hepatitis A. Travel insurance is recommended.</li>
          </ul>
        </li>
      </ul>
    `,
  },
  greece: {
    title: "Greece",
    description:
      "Greece is famous for its stunning landscapes and cold winters.",
    image: ` ${greece}`,
    text: `
    <h2>  Greece is a country rich in history, culture, and natural beauty, making it a captivating destination for travelers.</h2> Here’s a comprehensive guide to help you explore Greece:

      <ul>
        <li><strong>Major Destinations:</strong>
          <ul>
            <li><strong>Athens:</strong> The capital city is renowned for its ancient history. Key attractions include the Acropolis and Parthenon, the ancient Agora, the Temple of Olympian Zeus, and the Acropolis Museum. Don’t miss the charming Plaka district with its traditional Greek architecture and vibrant street life.</li>
            <li><strong>Santorini:</strong> Famous for its stunning sunsets, whitewashed buildings with blue domes, and dramatic cliffs. Key spots include Oia village, the beaches of Kamari and Perissa, and the ancient ruins of Akrotiri.</li>
            <li><strong>Mykonos:</strong> Known for its lively nightlife, beautiful beaches, and charming Cycladic architecture. Explore Mykonos Town (Chora) with its narrow streets and vibrant nightlife, and visit the nearby Delos island for its significant archaeological site.</li>
            <li><strong>Crete:</strong> The largest Greek island offers a mix of beautiful beaches, ancient ruins, and vibrant cities. Highlights include the Palace of Knossos, the Venetian harbor of Chania, and the charming town of Rethymnon.</li>
            <li><strong>Thessaloniki:</strong> Greece’s second-largest city is known for its vibrant culture, historical sites like the Rotunda and the White Tower, and lively markets. It also offers a rich culinary scene and vibrant nightlife.</li>
            <li><strong>Rhodes:</strong> Known for its medieval Old Town, beautiful beaches, and ancient ruins. Visit the Palace of the Grand Master, the ancient city of Kamiros, and enjoy the beaches of Lindos.</li>
          </ul>
        </li>

        <li><strong>Cultural Highlights:</strong>
          <ul>
            <li><strong>Ancient Ruins:</strong> Greece is filled with ancient archaeological sites. Beyond Athens and Crete, visit the Sanctuary of Delphi, the ancient theater of Epidaurus, and the ancient city of Corinth.</li>
            <li><strong>Festivals:</strong> Experience Greek culture through festivals such as the Athens and Epidaurus Festival, which features performances in ancient theaters, and the Patras Carnival, one of the largest and most colorful in Greece.</li>
            <li><strong>Traditional Music and Dance:</strong> Enjoy traditional Greek music and dance, such as the lively sirtaki. Many local tavernas and cultural events feature live performances of folk music and dancing.</li>
          </ul>
        </li>

        <li><strong>Cuisine:</strong>
          <ul>
            <li><strong>Moussaka:</strong> A classic Greek dish made with layers of eggplant, minced meat, and béchamel sauce, often baked until golden and bubbly.</li>
            <li><strong>Souvlaki:</strong> Grilled meat skewers, usually served with pita bread, vegetables, and tzatziki sauce. It's a popular street food across Greece.</li>
            <li><strong>Gyro:</strong> Similar to souvlaki, gyro is made from meat cooked on a vertical rotisserie, typically served in a pita with vegetables and tzatziki.</li>
            <li><strong>Greek Salad:</strong> A refreshing mix of tomatoes, cucumbers, olives, onions, and feta cheese, dressed with olive oil and oregano.</li>
            <li><strong>Baklava:</strong> A sweet pastry made of layers of phyllo dough filled with nuts and honey, popular throughout Greece and the Middle East.</li>
          </ul>
        </li>

        <li><strong>Natural Wonders:</strong>
          <ul>
            <li><strong>Meteora:</strong> Known for its monasteries perched on towering rock formations, Meteora is a UNESCO World Heritage site offering breathtaking views and a unique historical experience.</li>
            <li><strong>Navagio Beach:</strong> Also known as Shipwreck Beach, this stunning cove on Zakynthos is famous for its crystal-clear waters and a shipwreck that rests on the sand.</li>
            <li><strong>Mount Olympus:</strong> The highest peak in Greece, it’s ideal for hiking and offers stunning views. It's also known from Greek mythology as the home of the gods.</li>
            <li><strong>Samaria Gorge:</strong> Located in Crete, this dramatic gorge is a popular hiking destination, offering stunning natural scenery and a challenging trek through the rugged landscape.</li>
          </ul>
        </li>

        <li><strong>Adventure and Activities:</strong>
          <ul>
            <li><strong>Hiking and Trekking:</strong> Greece offers excellent hiking opportunities, from the trails of Mount Olympus to the scenic paths of the Samaria Gorge and the island hikes on Santorini and Crete.</li>
            <li><strong>Beach Activities:</strong> Enjoy Greece’s beautiful beaches with activities like swimming, snorkeling, and sailing. Popular beaches include Navagio Beach on Zakynthos, Elafonissi Beach in Crete, and Mykonos beaches.</li>
            <li><strong>Island Hopping:</strong> Explore multiple Greek islands in one trip. Ferry services connect islands like Santorini, Mykonos, Naxos, and Paros, each offering its own unique charm.</li>
            <li><strong>Scuba Diving and Snorkeling:</strong> Discover the underwater beauty of Greece with diving and snorkeling spots in places like the Aegean Sea and the Ionian Sea, known for their clear waters and marine life.</li>
          </ul>
        </li>

        <li><strong>Travel Tips:</strong>
          <ul>
            <li><strong>Language:</strong> Greek is the official language. While English is commonly spoken in tourist areas, learning a few basic Greek phrases can be helpful and appreciated by locals.</li>
            <li><strong>Currency:</strong> The local currency is the Euro (EUR). Credit cards are widely accepted, but carrying some cash is useful for smaller shops or rural areas.</li>
            <li><strong>Best Time to Visit:</strong> The best times to visit Greece are in the spring (April to June) and fall (September to October) when the weather is pleasant and the tourist crowds are smaller. Summer (July and August) can be very hot and crowded, especially in popular destinations.</li>
            <li><strong>Transportation:</strong> Greece has a well-developed transportation network, including domestic flights, ferries between islands, and buses. Renting a car is also a good option for exploring more remote areas.</li>
            <li><strong>Safety:</strong> Greece is generally a safe destination for travelers. Common travel precautions include keeping an eye on personal belongings and being cautious in busy tourist areas.</li>
          </ul>
        </li>
      </ul>

      Greece offers a blend of ancient history, stunning landscapes, and vibrant culture, making it a fantastic destination for any traveler. Whether you’re exploring ancient ruins, relaxing on beautiful beaches, or indulging in delicious Greek cuisine, Greece has something for everyone.
    `,
  },
  ireland: {
    title: " Ireland",
    description:
      "Ireland is famous for its stunning landscapes and cold winters.",
    image: ` ${ireland}`,
    text: `
    <h2>  Ireland is a captivating destination known for its stunning landscapes, rich history, and warm hospitality. </h2>Here’s a comprehensive guide for travelers:

      <ul>
        <li><strong>Major Cities to Visit:</strong>
          <ul>
            <li><strong>Dublin:</strong> The vibrant capital city is known for its literary history, lively pubs, and historic sites. Key attractions include Trinity College and the Book of Kells, Dublin Castle, St. Patrick’s Cathedral, and the Guinness Storehouse. Explore the Temple Bar district for a lively nightlife experience.</li>
            <li><strong>Cork:</strong> A dynamic city with a strong cultural scene, Cork offers attractions like the English Market, St. Fin Barre’s Cathedral, and the nearby Blarney Castle, where you can kiss the famous Blarney Stone.</li>
            <li><strong>Limerick:</strong> Known for its medieval charm, Limerick features King John’s Castle, the Hunt Museum, and a picturesque riverside setting along the Shannon River.</li>
            <li><strong>Galway:</strong> A lively city with a bohemian vibe, Galway is famous for its colorful streets, vibrant arts scene, and cultural festivals. Visit the Spanish Arch, Galway Cathedral, and enjoy the lively atmosphere in the Latin Quarter.</li>
            <li><strong>Kilkenny:</strong> Known for its well-preserved medieval architecture, Kilkenny features Kilkenny Castle, St. Canice’s Cathedral, and a charming old town with historic streets and pubs.</li>
          </ul>
        </li>

        <li><strong>Cultural Highlights:</strong>
          <ul>
            <li><strong>Traditional Music and Dance:</strong> Experience traditional Irish music and dance, including lively sessions in local pubs and performances of traditional Irish dancing, such as those seen in “Riverdance.”</li>
            <li><strong>Festivals:</strong> Ireland hosts a range of festivals throughout the year, including St. Patrick’s Day celebrations, Galway International Arts Festival, and the Cork Jazz Festival.</li>
            <li><strong>Historical Sites:</strong> Explore Ireland’s rich history through its many historical sites, such as Newgrange (a prehistoric monument older than the pyramids), the Rock of Cashel (a medieval fortress), and the monastic site of Glendalough.</li>
          </ul>
        </li>

        <li><strong>Natural Wonders:</strong>
          <ul>
            <li><strong>Cliffs of Moher:</strong> One of Ireland’s most iconic natural landmarks, the Cliffs of Moher offer stunning views over the Atlantic Ocean and dramatic cliffside scenery.</li>
            <li><strong>Killarney National Park:</strong> Located in County Kerry, this park is known for its beautiful lakes, forests, and the stunning Muckross House and Gardens. It’s a great place for hiking, cycling, and scenic drives.</li>
            <li><strong>The Burren:</strong> A unique karst landscape in County Clare, the Burren is characterized by its rugged limestone terrain, rare flora, and ancient stone monuments.</li>
            <li><strong>Connemara:</strong> Known for its wild and rugged beauty, Connemara features dramatic landscapes, including mountains, lakes, and coastal scenery. Explore the picturesque town of Clifden and the stunning Kylemore Abbey.</li>
            <li><strong>Giant’s Causeway:</strong> A UNESCO World Heritage site in Northern Ireland, the Giant’s Causeway is famous for its unique basalt columns formed by volcanic activity.</li>
          </ul>
        </li>

        <li><strong>Cuisine:</strong>
          <ul>
            <li><strong>Irish Stew:</strong> A hearty and traditional dish made with lamb or beef, potatoes, carrots, and onions, often enjoyed with a slice of soda bread.</li>
            <li><strong>Fish and Chips:</strong> A classic dish of fried fish and crispy chips, commonly found in pubs and eateries throughout Ireland.</li>
            <li><strong>Soda Bread:</strong> A traditional Irish bread made with baking soda, buttermilk, and often enriched with ingredients like raisins or seeds.</li>
            <li><strong>Boxty:</strong> A type of potato pancake that can be served as a side dish or filled with various ingredients.</li>
            <li><strong>Irish Breakfast:</strong> A hearty meal typically consisting of bacon, sausages, eggs, black and white pudding, tomatoes, and baked beans, served with toast or soda bread.</li>
          </ul>
        </li>

        <li><strong>Adventure and Activities:</strong>
          <ul>
            <li><strong>Hiking and Walking:</strong> Ireland offers numerous trails and walking routes, including the Wicklow Way, the Ring of Kerry, and the Dingle Way. Explore the scenic trails and enjoy the country’s natural beauty.</li>
            <li><strong>Golf:</strong> Ireland is renowned for its world-class golf courses, including Ballybunion, Royal County Down, and Lahinch. The country offers a variety of challenging and scenic courses.</li>
            <li><strong>Cycling:</strong> Explore Ireland’s landscapes on two wheels with dedicated cycling routes, such as the Great Western Greenway in County Mayo and the Waterford Greenway.</li>
            <li><strong>Fishing and Water Sports:</strong> Ireland’s rivers and lakes are ideal for fishing, while its coastal areas offer opportunities for surfing, kayaking, and sailing.</li>
          </ul>
        </li>

        <li><strong>Travel Tips:</strong>
          <ul>
            <li><strong>Language:</strong> English is the primary language spoken in Ireland, although Irish (Gaelic) is also an official language and can be seen on road signs and in some local communities.</li>
            <li><strong>Currency:</strong> The currency used in the Republic of Ireland is the Euro (EUR). In Northern Ireland (part of the UK), the currency is the British Pound (GBP).</li>
            <li><strong>Weather:</strong> Ireland’s weather can be unpredictable, with frequent rain and variable temperatures. Pack layers and be prepared for changing conditions.</li>
            <li><strong>Transportation:</strong> Ireland has a well-connected public transportation system, including buses, trains, and ferries. Renting a car is also a popular option for exploring rural areas and scenic drives.</li>
            <li><strong>Safety:</strong> Ireland is generally a safe destination for travelers. Common travel precautions include keeping an eye on personal belongings and being aware of local weather conditions, especially if you’re exploring remote areas.</li>
          </ul>
        </li>
      </ul>

      Ireland offers a rich tapestry of experiences, from its historic sites and vibrant cities to its breathtaking natural landscapes. Whether you’re interested in cultural exploration, outdoor adventure, or simply enjoying the warmth of Irish hospitality, Ireland has something for everyone.
    `,
  },
};

//spain
export const spainHotels = [
  {
    id: "0",
    coverImage: `${spain0}`,
    description:
      "Well situated in the center of Bilbao, GOIEN BOUTIQUE HOTEL provides buffet breakfast and free WiFi throughout the property. This 2-star hotel offers a bar. Some units at the property feature a terrace with a city view. The hotel will provide guests with air-conditioned rooms with a desk, a coffee machine, a fridge, a safety deposit box, a flat-screen TV and a private bathroom with a shower. At GOIEN BOUTIQUE HOTEL all rooms come with bed linen and towels. ",
    name: "GOIEN BOUTIQUE HOTEL",
    link: "https://www.booking.com/hotel/es/goien-boutique.html?aid=304142&label=gen173nr-1DCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQPoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIE4AIB&sid=298312d173cbe9a44578a812e5e4bf09&all_sr_blocks=1177636701_392541643_1_0_0;checkin=2025-09-16;checkout=2025-09-21;dest_id=197;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=1177636701_392541643_1_0_0;hpos=1;matching_block_id=1177636701_392541643_1_0_0;nflt=price%3DEUR-min-max-1%3Breview_score%3D90;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=1177636701_392541643_1_0_0__112875;srepoch=1727272294;srpvid=3e46614886fd110b;type=total;ucfs=1&",
  },

  {
    _id: "1",
    coverImage: `${spain1}`,
    description:
      "Located in Guía de Isora, 1.7 miles from Playa del Barranco de Erques, Los Jardines de Abama Suites has accommodations with an outdoor swimming pool, free private parking, a fitness center and a garden. Offering a bar, the property is located within 8.1 miles of Los Gigantes.",
    name: "Los Jardines de Abama Suites",
    link: "https://www.booking.com/hotel/es/los-jardines-de-abama.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=725113003_359468763_0_1_0_76126;checkin=2025-10-10;checkout=2025-10-12;dest_id=197;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=725113003_359468763_0_1_0_76126;hpos=1;matching_block_id=725113003_359468763_0_1_0_76126;nflt=review_score%3D90%3Bprice%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=725113003_359468763_0_1_0_76126_68026;srepoch=1727274678;srpvid=71f0660a3fa50419;type=total;ucfs=1&",
  },
  {
    _id: "2",
    coverImage: `${spain2}`,
    description:
      "Five-star hotel located right in the historic center of Seville, in a privileged area of the city. The building was the superb Pickman Palace which has been refurbished with the highest standards and innovation to offer our guests the best exclusivity and comfort.",
    name: "Hotel Lobby     ",
    link: "https://www.booking.com/hotel/es/lobby-room-sevilla.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=377695807_242785582_2_1_0_431889;checkin=2025-10-10;checkout=2025-10-12;dest_id=197;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=4;highlighted_blocks=377695807_242785582_2_1_0_431889;hpos=4;matching_block_id=377695807_242785582_2_1_0_431889;nflt=review_score%3D90%3Bprice%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=377695807_242785582_2_1_0_431889_70585;srepoch=1727274789;srpvid=71f0660a3fa50419;type=total;ucfs=1&",
  },
  {
    _id: "3",
    coverImage: `${spain3}`,
    description:
      "A hotel designed by the renowned interior designer Lázaro Rosa-Violán with a concept that connects the charm of the Andalusian spirit with the feeling of discovering the city for the first time.",
    name: "Only YOU Hotel Sevilla ",
    link: "https://www.booking.com/hotel/es/avenida-kansas-sevilla.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=9050947_383059339_2_2_0_415837;checkin=2025-10-10;checkout=2025-10-12;dest_id=197;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=5;highlighted_blocks=9050947_383059339_2_2_0_415837;hpos=5;matching_block_id=9050947_383059339_2_2_0_415837;nflt=review_score%3D90%3Bprice%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=9050947_383059339_2_2_0_415837_103194;srepoch=1727274963;srpvid=71f0660a3fa50419;type=total;ucfs=1&",
  },
  {
    _id: "4",
    coverImage: `${spain4}`,
    description:
      "Well situated in the center of Barcelona, ME Barcelona has air-conditioned rooms, a terrace, free WiFi and a restaurant. This 5-star hotel offers room service and a concierge service. Guests can have a drink at the bar.",
    name: "ME Barcelona ",
    link: "https://www.booking.com/hotel/es/me-barcelona-barcelona.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=693981311_285547222_2_1_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=197;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=3;highlighted_blocks=693981311_285547222_2_1_0;hpos=3;matching_block_id=693981311_285547222_2_1_0;nflt=review_score%3D90%3Bprice%3DEUR-760-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=693981311_285547222_2_1_0__156000;srepoch=1727275118;srpvid=71f0660a3fa50419;type=total;ucfs=1&",
  },
];

//canada
export const canadaHotels = [
  {
    id: "0",
    coverImage: `${canada0}`,
    description:
      "Overlooking serene Brentwood Bay near Victoria, British Columbia, this tranquil adult-only retreat offers spacious rooms with cozy fireplaces and breathtaking ocean views. Unwind in the luxurious spa, savor delectable cuisine at the on-site restaurants, or take a refreshing dip in the outdoor pool.",
    name: "Brentwood Bay Resort & Spa",
    link: "https://www.booking.com/hotel/ca/brentwood-bay-lodge-and-spa.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=7635405_295310653_0_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=38;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=7635405_295310653_0_0_0;hpos=1;matching_block_id=7635405_295310653_0_0_0;nflt=class%3D5%3Bprice%3DEUR-330-max-1;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=7635405_295310653_0_0_0__154620;srepoch=1727275262;srpvid=916b672dcd900143;type=total;ucfs=1&",
  },

  {
    _id: "1",
    coverImage: `${canada1}`,
    description:
      "The air-conditioned villa also provides a flat-screen TV, a fully equipped kitchen with a dishwasher, a seating area, washing machine, and 3 bathrooms with a hot tub and a shower. The accommodation is non-smoking.",
    name: "Sherkston Oasis - Hot Tub Soaks & Luxurious Escape    ",
    link: "https://www.booking.com/hotel/ca/sherkston-castle-w-heated-pool-amp-hot-tub.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=1106645301_383460796_16_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=-571567;dest_type=city;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=1106645301_383460796_16_0_0;hpos=1;matching_block_id=1106645301_383460796_16_0_0;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;sr_pri_blocks=1106645301_383460796_16_0_0__6000000;srepoch=1727275453;srpvid=e26e678c9a4f0dcf;type=total;ucfs=1&",
  },
  {
    _id: "2",
    coverImage: `${canada2}`,
    description:
      "Guests staying at the Lethbridge Lodge can enjoy 2 different on-site dining options for lunch, dinner or refreshments.The hotel also provides completely nonsmoking accommodations.",
    name: "Sandman Signature Lethbridge Lodge  ",
    link: "https://www.booking.com/hotel/ca/lethbridge-lodge.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=7654015_199704128_2_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=38;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=9;highlighted_blocks=7654015_199704128_2_0_0;hpos=9;matching_block_id=7654015_199704128_2_0_0;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=7654015_199704128_2_0_0__1999800;srepoch=1727275670;srpvid=3fbf6773635c0b3e;type=total;ucfs=1&",
  },

  {
    _id: "3",
    coverImage: `${canada3}`,
    description:
      "The Watermark Beach Resort has 2 outdoor hot tubs and a fitness center. A steam room and wine bar with an outdoor patio are also available at the hotel.",
    name: "Watermark Beach Resort  ",
    link: "https://www.booking.com/hotel/ca/the-watermark-beach-resort.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=17238402_347531932_2_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=38;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=11;highlighted_blocks=17238402_347531932_2_0_0;hpos=11;matching_block_id=17238402_347531932_2_0_0;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=17238402_347531932_2_0_0__1999800;srepoch=1727275748;srpvid=3fbf6773635c0b3e;type=total;ucfs=1&",
  },
  {
    _id: "4",
    coverImage: `${canada4}`,
    description:
      "Located in the heart of Whistler Village, this lodge-style hotel offers ski to door access to Whistler Village Gondola, 2 minutes’ walk away. It features a year-round outdoor pool and hot tub.  A spa bath is offered in all rooms.",
    name: "Mountain Side Hotel Whistler by Executive",
    link: "https://www.booking.com/hotel/ca/mountain-side-whistler-by-executive.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=40682304_267220519_2_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=38;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=12;highlighted_blocks=40682304_267220519_2_0_0;hpos=12;matching_block_id=40682304_267220519_2_0_0;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=40682304_267220519_2_0_0__1999800;srepoch=1727275813;srpvid=3fbf6773635c0b3e;type=total;ucfs=1&",
  },
];

//greece
export const greeceHotels = [
  {
    id: "0",
    coverImage: `${greece0}`,
    description:
      "Offering a garden, Adam's Suite offers accommodations in Lefkada. The air-conditioned accommodation is a 19-minute walk from Agios Ioannis Beach, and guests can benefit from on-site private parking and complimentary Wifi. The apartment provides an outdoor swimming pool and an elevator.",
    name: "Adam's Suite    ",
    link: "https://www.booking.com/hotel/gr/adams-suite.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=984600901_373376654_2_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=83;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=984600901_373376654_2_0_0;hpos=1;matching_block_id=984600901_373376654_2_0_0;nflt=price%3DEUR-min-max-1%3Breview_score%3D90;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=984600901_373376654_2_0_0__848160;srepoch=1727277539;srpvid=2bf16ba5e7f304da;type=total;ucfs=1&",
  },

  {
    _id: "1",
    coverImage: `${greece1}`,
    description:
      "Just a 8-minute walk from Paradise Beach and 0.7 miles from Pinky Beach, Luxury Villa Platis Gialos Pool features accommodations in Paradise Beach with an outdoor swimming pool. This property offers access to a balcony and free private parking. The property offers barbecue facilities and outdoor furniture.",
    name: "Luxury Villa Platis Gialos Pool ",
    link: "https://www.booking.com/hotel/gr/6-bedrooms-villa-at-platis-gialos-800-m-away-from-the-beach-with-sea-view-privat.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=956684402_397368787_12_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=83;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=4;highlighted_blocks=956684402_397368787_12_0_0;hpos=4;matching_block_id=956684402_397368787_12_0_0;nflt=price%3DEUR-min-max-1%3Breview_score%3D90;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=956684402_397368787_12_0_0__593000;srepoch=1727277656;srpvid=2bf16ba5e7f304da;type=total;ucfs=1&",
  },
  {
    _id: "2",
    coverImage: `${greece2}`,
    description:
      "Paros Breeze Odyssey Suites provides a garden and accommodations with a kitchen in Drios, a 8-minute walk from Drios Beach. This property offers a private pool and free private parking. Venetian Harbor and Castle is 11 miles from the vacation home, and Archaeological Museum of Paros is 13 miles away.",
    name: "Paros Breeze Odyssey Suites",
    link: "https://www.booking.com/hotel/gr/paros-breeze-odyssey-suites.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=1062604802_378603442_5_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=83;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=10;highlighted_blocks=1062604802_378603442_5_0_0;hpos=10;matching_block_id=1062604802_378603442_5_0_0;nflt=price%3DEUR-min-max-1%3Breview_score%3D90;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=1062604802_378603442_5_0_0__360000;srepoch=1727281265;srpvid=2bf16ba5e7f304da;type=total;ucfs=1&",
  },

  {
    _id: "3",
    coverImage: `${greece3}`,
    description:
      "Located in Mikonos, a 3-minute walk from Agia Anna Beach, Manu Mykonos Next to the Beach Quality Brand Villa & Bungalows has accommodations with a seasonal outdoor swimming pool, free private parking, a garden and barbecue facilities. ",
    name: "Manu Mykonos Next to the Beach Quality Brand Villa & Bungalows ",
    link: "https://www.booking.com/hotel/gr/manu-quality-brand-residence-mykonos-open-the-window-and-you-are-just-steps-away.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=515108211_178158398_8_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=83;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=515108211_178158398_8_0_0;hpos=1;matching_block_id=515108211_178158398_8_0_0;nflt=ht_id%3D204;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=515108211_178158398_8_0_0__237600;srepoch=1727281395;srpvid=2bf16ba5e7f304da;type=total;ucfs=1&",
  },
  {
    _id: "4",
    coverImage: `${greece4}`,
    description:
      "Within proximity of Chora in Mykonos, the awarded hotel Cavo Tagoo features an infinity pool with a 40-yard aquarium bar, and a fully equipped spa with indoor pool. Most rooms enjoy sea views.",
    name: "Cavo Tagoo Mykonos     ",
    link: "https://www.booking.com/hotel/gr/cavo-tagoo-mykonos1.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=30013110_372532290_0_1_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=83;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=22;highlighted_blocks=30013110_372532290_0_1_0;hpos=22;matching_block_id=30013110_372532290_0_1_0;nflt=ht_id%3D204;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=30013110_372532290_0_1_0__137700;srepoch=1727281475;srpvid=2bf16ba5e7f304da;type=total;ucfs=1&",
  },
];
//indonesia
export const indonesiaHotels = [
  {
    id: "0",
    coverImage: `${indonesia0}`,
    description:
      "The Hidden Palace by Hanging Gardens is located in Payangan, just 7.8 miles from Neka Art Museum and 8.1 miles from Tegallalang Rice Terrace. Guests staying at this villa have access to free Wifi, a fully equipped kitchen, and a balcony.",
    name: "The Hidden Palace by Hanging Gardens    ",
    link: "https://www.booking.com/hotel/id/hidden-palace-by-hanging-gardens.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=1099093101_382772500_0_1_0_221637;checkin=2025-10-10;checkout=2025-10-12;dest_id=99;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=2;highlighted_blocks=1099093101_382772500_0_1_0_221637;hpos=2;matching_block_id=1099093101_382772500_0_1_0_221637;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=1099093101_382772500_0_1_0_221637_119599283600;srepoch=1727281644;srpvid=577c73a90d790df9;type=total;ucfs=1&",
  },

  {
    _id: "1",
    coverImage: `${indonesia1}`,
    description:
      "This luxurious cliff-edge resort is set 492 feet above the Indian Ocean, affording stunning views of surrounding landscapes. Offering personalized service, The Ungasan Clifftop Resort features a private beachfront, fitness facilities and a day spa.",
    name: "The Ungasan Clifftop Resort",
    link: "https://www.booking.com/hotel/id/the-ungasan-clifftop-resort.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=32855127_335373602_7_1_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=99;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=7;highlighted_blocks=32855127_335373602_7_1_0;hpos=7;matching_block_id=32855127_335373602_7_1_0;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=32855127_335373602_7_1_0__8236800000;srepoch=1727281743;srpvid=577c73a90d790df9;type=total;ucfs=1&",
  },
  {
    _id: "2",
    coverImage: `${indonesia2}`,
    description:
      "Located in trendy Seminyak, Villa Lilibel by Elite Havens - an elite haven offers luxury accommodations with a large private pool. Guests can enjoy relaxing massages at the spa center. Free private parking is available on site and free WiFi is accessible throughout the property.",
    name: "Villa Lilibel by Elite Havens",
    link: "https://www.booking.com/hotel/id/villa-lilibel-an-elite-haven.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=137717301_335430990_12_1_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=99;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=8;highlighted_blocks=137717301_335430990_12_1_0;hpos=8;matching_block_id=137717301_335430990_12_1_0;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=137717301_335430990_12_1_0__7062416200;srepoch=1727281869;srpvid=577c73a90d790df9;type=total;ucfs=1&",
  },

  {
    _id: "3",
    coverImage: `${indonesia3}`,
    description:
      "Surrounded by rice terraces overlooking the Indian Ocean, Soori Bali offers villas with free wireless internet access. Each villa features a private pool and views of the sea.",
    name: "Soori Bali",
    link: "https://www.booking.com/hotel/id/soori-bali.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=17686804_233929237_0_1_0_614578;checkin=2025-10-10;checkout=2025-10-12;dest_id=99;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=34;highlighted_blocks=17686804_233929237_0_1_0_614578;hpos=9;matching_block_id=17686804_233929237_0_1_0_614578;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=17686804_233929237_0_1_0_614578_4026912000;srepoch=1727282033;srpvid=577c73a90d790df9;type=total;ucfs=1&",
  },
  {
    _id: "4",
    coverImage: `${indonesia4}`,
    description:
      "Buahan, a Banyan Tree Escape features free bikes, outdoor swimming pool, a garden and shared lounge in Payangan. The property is around 10 miles from Neka Art Museum, 11 miles from Tegallalang Rice Terrace and 11 miles from Blanco Museum.",
    name: "Buahan, a Banyan Tree Escape",
    link: "https://www.booking.com/hotel/id/buahan-a-banyan-tree-escape.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=753444903_396783568_0_25_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=99;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=38;highlighted_blocks=753444903_396783568_0_25_0;hpos=13;matching_block_id=753444903_396783568_0_25_0;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=753444903_396783568_0_25_0__3920400000;srepoch=1727282033;srpvid=577c73a90d790df9;type=total;ucfs=1&",
  },
];

//ireland
export const irelandHotels = [
  {
    id: "0",
    coverImage: `${ireland0}`,
    description:
      "For peace and relaxation, Cliff House has amazing sea views, excellent cuisine and spa facilities on the coast at Ardmore. It offers chic, boutique-style rooms, with free Wi-Fi and free parking on site.",
    name: "Cliff House Hotel ",
    link: "https://www.booking.com/hotel/ie/cliff-house.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=4116901_390493041_0_1_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=102;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=7;highlighted_blocks=4116901_390493041_0_1_0;hpos=7;matching_block_id=4116901_390493041_0_1_0;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=4116901_390493041_0_1_0__117500;srepoch=1727282296;srpvid=e30a74c0b78408b1;type=total;ucfs=1&",
  },

  {
    _id: "1",
    coverImage: `${ireland1}`,
    description:
      "Doleen Quay House is located in Ballyconneely, just 1.7 miles from Mannin Bay Blueway Beach and 4.7 miles from Alcock & Brown Memorial.",
    name: "Doleen Quay House",
    link: "https://www.booking.com/hotel/ie/doleen-quay-house.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=640434202_397123467_0_0_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=102;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=1;highlighted_blocks=640434202_397123467_0_0_0;hpos=1;matching_block_id=640434202_397123467_0_0_0;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=640434202_397123467_0_0_0__1050830;srepoch=1727282381;srpvid=e30a74c0b78408b1;type=total;ucfs=1&",
  },
  {
    _id: "2",
    coverImage: `${ireland2}`,
    description:
      "Overlooking mountains and lakes, the 5-star Aghadoe Heights Hotel is 2 miles from Killarney town center. It offers an indoor pool, an award-winning restaurant, and transportation from Kerry Airport.",
    name: "Aghadoe Heights Hotel & Spa    ",
    link: "https://www.booking.com/hotel/ie/aghadoe-heights-spa.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=4104516_376284208_0_41_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=102;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=18;highlighted_blocks=4104516_376284208_0_41_0;hpos=18;matching_block_id=4104516_376284208_0_41_0;nflt=price%3DEUR-min-max-1%3Bclass%3D5;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=4104516_376284208_0_41_0__47800;srepoch=1727282463;srpvid=e30a74c0b78408b1;type=total;ucfs=1&",
  },

  {
    _id: "3",
    coverImage: `${ireland3}`,
    description:
      "Delphi Resort Hotel & Spa offers 4-star hotel accommodations, with a spa, an adventure center and two restaurants. There is free WiFi and some of the sophisticated bedrooms feature a balcony, with beautiful views.",
    name: "Delphi Resort Hotel & Spa",
    link: "https://www.booking.com/hotel/ie/delphi-mountain-resort.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=17956803_397721712_0_33_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=102;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=3;highlighted_blocks=17956803_397721712_0_33_0;hpos=3;matching_block_id=17956803_397721712_0_33_0;nflt=price%3DEUR-min-max-1%3Bht_id%3D206;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=17956803_397721712_0_33_0__39800;srepoch=1727282730;srpvid=e30a74c0b78408b1;type=total;ucfs=1&",
  },
  {
    _id: "4",
    coverImage: `${ireland4}`,
    description:
      "Located less than an hour’s drive from Dublin, this hotel provides a luxury retreat away from the stresses of everyday life and is perfectly located for exploring south-east Ireland.",
    name: "Seafield Hotel & Spa Resort",
    link: "https://www.booking.com/hotel/ie/seafield-golf-spa.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaBGIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AoKq0LcGwAIB0gIkZmVlMGE4MTEtMjQ0Ny00ZDI5LWE4NTctMmMxYTY5MGRhYzRk2AIF4AIB&sid=a6b238fe70a8045a378828586d2fd2cb&all_sr_blocks=4046201_331156267_2_2_0;checkin=2025-10-10;checkout=2025-10-12;dest_id=102;dest_type=country;dist=0;group_adults=1;group_children=0;hapos=2;highlighted_blocks=4046201_331156267_2_2_0;hpos=2;matching_block_id=4046201_331156267_2_2_0;nflt=price%3DEUR-min-max-1%3Bht_id%3D206;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=price_from_high_to_low;sr_pri_blocks=4046201_331156267_2_2_0__55430;srepoch=1727282795;srpvid=e30a74c0b78408b1;type=total;ucfs=1&",
  },
];
