import React from "react";
import Header from "../../components/header/Header";
import Home from "../Home/Home";
import CookieConsent from "../../components/CookieContest/CookieContest";
import WhyUs from "../../components/WhyUs/WhyUs";
import Advice from '../../components/Advice/Advice'
import Faq from '../../components/faq/Faq'
import Gallery from '../../components/Gallery/Gallery'
import Testimonials from '../../components/testimonials/Testimonials'
export default function Layout() {
  return (
    <div>
   
      <Home />
      <Gallery />
      <Advice />
      <CookieConsent />
      <WhyUs />
      <Testimonials />
      <Faq />
    </div>
  );
}
