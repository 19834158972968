import React from "react";
import AboutUs from "./components/AboutDetail";
import Advice from "../../components/Advice/Advice";
import styles from "./about.module.css";
import banner from "../../images/country/about.jpg";
import Testimonials from '../../components/testimonials/Testimonials'

const About = () => {
  return (
    <>
      <div className={styles.banner}>
        <img
          src={banner}
          className={styles.bannerImage}
          alt="Picture of the author"
        />{" "}
        <div className={styles.text}>
          <h2>About Us</h2>
        </div>
      </div>

      <>
        <AboutUs />
        <Advice />
        <Testimonials/>
      </>
    </>
  );
};

export default About;
