"use client";

import { useEffect } from "react";
import styles from "./gallery.module.css";
import {Link} from "react-router-dom";

const Gallery = () => {
  useEffect(() => {
    const panels = document.querySelectorAll(`.${styles.panel}`);

    function toggleOpen() {
      this.classList.toggle(styles.open);
    }

    function toggleActive(e) {
      if (e.propertyName.includes("flex")) {
        this.classList.toggle(styles["open-active"]);
      }
    }

    panels.forEach((panel) => panel.addEventListener("click", toggleOpen));
    panels.forEach((panel) =>
      panel.addEventListener("transitionend", toggleActive)
    );

    return () => {
      panels.forEach((panel) => panel.removeEventListener("click", toggleOpen));
      panels.forEach((panel) =>
        panel.removeEventListener("transitionend", toggleActive)
      );
    };
  }, []);

  return (
    <section className={styles.images}>
      <h2 className={styles.title}>Top Destinations </h2>
      <div className={styles.panels}>
        <div className={`${styles.panel} ${styles.panel1}`}>
          <Link to='/country/spain' >Spain</Link>
        </div>
        <div className={`${styles.panel} ${styles.panel2}`}>
        <Link to='/country/spain' >Indonesia</Link>
        </div>
        <div className={`${styles.panel} ${styles.panel3}`}>
        <Link to='/country/spain' >Canada</Link>
        </div>
        <div className={`${styles.panel} ${styles.panel4}`}>
        <Link to='/country/spain' >Ireland</Link>
        </div>
        <div className={`${styles.panel} ${styles.panel5}`}>
        <Link to='/country/spain' >Greece</Link>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
