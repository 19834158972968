import img01 from '../../../images/casinoImages/anoment.webp'
import img02 from '../../../images/casinoImages/cuisne.webp'
import img03 from '../../../images/casinoImages/event.webp'
import img04 from '../../../images/casinoImages/museum.webp'
import img05 from '../../../images/casinoImages/guid.webp'
import img06 from '../../../images/casinoImages/shop.webp'
import img07 from '../../../images/casinoImages/beach.webp'
import img08 from '../../../images/casinoImages/local.webp'


export const dataCasino = [

    {
      id: 1,
      title: "Explore Local Landmarks and Monuments",
      desc: "Visit popular historical sites, architectural wonders, and cultural landmarks to learn more about the country’s heritage.",
      img: img01,
    },
    {
      id: 2,
      title: "Try Local Cuisine",
      desc: "Sample traditional dishes and local specialties to immerse yourself in the culture through food.",
      img: img02,
    },
  
    {
      id: 3,
      title: "Attend Cultural Events or Festivals",
      desc: "Experience the local culture by attending festivals, concerts, or traditional performances.",
      img: img03,
    },
    {
      id: 4,
      title: "Visit Museums and Art Galleries",
      desc: "ILearn about the history, art, and culture of the country through its museums and galleries.",
      img: img04,
    },
    {
      id: 5,
      title: "Take a Guided City Tour",
      desc: "Join a walking tour, bus tour, or even a bicycle tour to explore the main attractions of the city with insights from a local guide.",
      img: img05,
    },
    {
      id: 6,
      title: "Learn a New Skill or Take a Local Workshop",
      desc: "Join a cooking class, craft-making workshop, or another cultural activity to gain a new skill and deeper understanding of the country.",
      img:img06,
    },
  
  
    {
      id: 7,
      title: "Relax at Popular Beaches or Resorts",
      desc: "Take some time to unwind at the beach, enjoy the local seaside resorts, or participate in water sports.",
      img: img07,
    },
    {
      id: 8,
      title: "Interact with Locals",
      desc: "Make an effort to meet locals, learn a few phrases in their language, and gain authentic insights into daily life.    ",
      img: img08,
    },
  ];