import React from 'react';
import styles from  './whyUs.module.css';

const WhyUs = () => {
  return (
    <section className={styles.whyUs}>
      <h2>Why Choose Us?</h2>
      <div className={styles.whyUsContent}>
        <div className={styles.whyUsRow}>
          <div>
            <h3> Personalized Travel Suggestions</h3>
            <p>We provide custom recommendations to make each journey memorable. From top tourist destinations to off-the-beaten-path adventures, our experts ensure you have the best travel insights for a perfect experience.</p>
          </div>
        </div>
        <div className={styles.whyUsRow}>
          <div>
            <h3>Relied Upon by Numerous Travelers</h3>
            <p>Countless travelers have trusted us with their vacation planning. With glowing reviews from satisfied customers, we’re committed to delivering exceptional service and ensuring a smooth travel experience for you.</p>
          </div>
        </div>
        <div className={styles.whyUsRow}>
          <div>
            <h3>Eco-Friendly Travel Choices</h3>
            <p>We promote eco-friendly accommodations and sustainable travel methods. You can travel responsibly by selecting hotels and practices that care for the planet and benefit local communities.</p>
          </div>
        </div>
        <div className={styles.whyUsRow}>
          <div>
            <h3>Round-the-Clock Assistance</h3>
            <p>Our customer service is available 24/7 to address any travel-related issues. From managing reservations to providing travel advice, our team is here to assist you at every stage of your trip.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
