import React from "react";
import styles from "./cuisine.module.css";
import Card from "./components/Card";
import greece from "../../images/country/greece.webp"
import indonesia from '../../images/country/indonesia.webp'
import spain from '../../images/country/spain.webp'
import ireland from '../../images/country/ireland.webp'
import canada from '../../images/country/canada.webp'
import banner from  '../../images/country/about0.avif'
export const data = [
  {
    id: 0,
    title: "Greece",
    img: greece,
    bottomDesc:
      "Greece is renowned for its ancient history, stunning islands, and rich Mediterranean culture. Home to iconic landmarks such as the Parthenon in Athens and the ancient ruins of Delphi, Greece’s historical significance is unparalleled. The country's islands, like Santorini and Mykonos, are famous for their whitewashed buildings, clear blue waters, and picturesque sunsets. Greece’s cuisine, featuring olives, feta cheese, and fresh seafood, is a delight for food lovers. Whether you’re wandering through the historic streets of Athens or relaxing on a sun-soaked beach, Greece offers a perfect blend of cultural heritage and natural beauty. ",
  },
  {
    id: 1,
    title: "Indonesia",
    bottomDesc:
      "Indonesia is an archipelago of over 17,000 islands, each offering its own unique blend of culture, landscapes, and experiences. Bali is the most famous island, drawing travelers with its vibrant nightlife, beautiful beaches, and rich spiritual traditions. However, Indonesia offers much more, from the lush jungles of Sumatra to the ancient temples of Yogyakarta, like Borobudur and Prambanan. Indonesia is a haven for nature enthusiasts with opportunities for scuba diving, volcano hiking, and exploring the pristine marine life of Raja Ampat. Its diverse culture, rich with traditions, ceremonies, and local crafts, captivates visitors from all over the world. ",
    img: indonesia,
  },
  {
    id: 3,
    title: "Spain",
    bottomDesc:
      "Spain is a country rich in history, art, and vibrant culture. From the dynamic streets of Barcelona, where Gaudí's architectural masterpieces like La Sagrada Familia stand, to the lively festivals like La Tomatina and the Running of the Bulls, Spain offers travelers a blend of tradition and modernity. Its coastal regions, such as Costa Brava and Costa del Sol, boast stunning beaches perfect for relaxation, while cities like Madrid are famous for their world-class museums, like the Prado, and energetic nightlife. Spain’s diverse landscapes, including the Pyrenees mountains and the Canary Islands, offer adventures for every type of traveler.",
    img: spain,
  },
  {
    id: 4,
    title: "Ireland",
    bottomDesc:
      "Ireland, known as the Emerald Isle, captivates visitors with its rolling green hills, dramatic coastlines, and rich folklore. Dublin, the capital, is famous for its literary history and lively pubs, while the countryside is dotted with ancient castles and charming villages. Sites like the Cliffs of Moher and the Ring of Kerry provide stunning natural landscapes that attract hikers and photographers alike. Ireland's warm hospitality, traditional music, and cultural festivals, like St. Patrick’s Day, make it a destination full of heart and soul. Whether you're exploring the rugged west coast or the urban delights of Belfast, Ireland offers an unforgettable journey into both history and nature.",
    img:ireland,
  },
  {
    id: 5,
    title: "Canada",
    bottomDesc:
      "Canada is known for its breathtaking natural beauty and vast wilderness. From the towering Rocky Mountains and crystal-clear lakes of Banff and Jasper National Parks to the dramatic Niagara Falls, nature lovers will find endless opportunities for outdoor adventures. Canada’s cities, like Toronto, Vancouver, and Montreal, offer a cosmopolitan feel with multicultural influences, diverse culinary scenes, and thriving arts and culture communities. Whether it’s exploring the rugged coastlines of Nova Scotia or discovering wildlife in the Yukon, Canada is a paradise for those seeking both adventure and serenity.",
    img: canada,
  },
];
export default function Blogs() {
  return (
    <div style={{ width: "100%" }}>
      <div className={styles.banner}>
        <img
          src={banner}
          width={500}
          height={500}
          alt="Picture of the author"
        />{" "}
        <div className={styles.text}>
          <h2>Blogs</h2>
        </div>
      </div>

      <div className={styles.cuisine}>
        <div className={styles.cuisine_text}>
          <h3 className={styles.cuisine_text_title}>Top Destinations </h3>
          <p className={styles.cuisine_text_desc}>
            Discover the most sought-after destinations for travelers around the
            world. Whether you're seeking sun, adventure, or culture, these
            destinations have something unique to offer.
          </p>
        </div>

        <div className={styles.cuisine_wrapper}>
          {data &&
            data.length > 0 &&
            data.map((item, key) => <Card data={item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
}
