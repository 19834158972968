import React from 'react';
import styles from './about.module.css';

const AboutUs = () => {
  return (
    <section className={styles.aboutUs}>
      <h2>Welcome to FindHotelToday</h2>
      <div className={styles.content}>

        <h3>24/7 Support: We’re Here Whenever You Need Us</h3>
        <p>
          At FindHotelToday, we believe that exceptional service doesn’t stop at booking. We know that travel can be unpredictable, and plans can change 
          unexpectedly. That’s why we offer 24/7 customer support to ensure that you’re always taken care of. Whether you need help with a last-minute booking, 
          directions to your hotel, or assistance during your stay, our friendly and knowledgeable team is available around the clock to assist you. Our goal is to 
          provide peace of mind from the moment you begin your journey until you return home. You can contact us via phone, email, or chat—whichever is most convenient for you.
        </p>

        <h3>Expert Travel Advice and Insider Tips</h3>
        <p>
          Planning a trip can be overwhelming, especially if you’re visiting a new destination. At FindHotelToday, we’re more than just a booking platform; we’re your 
          personal travel advisor. Our team of local experts and travel enthusiasts are passionate about helping you discover the hidden treasures and must-see attractions of your destination. Whether you’re looking for the best historical landmarks, charming villages, or scenic nature spots, our blog and travel guides are filled with 
          recommendations to make your trip truly memorable. Plus, if you’re unsure of where to go, our personalized suggestions will help you find the perfect destination 
          based on your preferences.
        </p>

        <h3>A Diverse Range of Accommodation Options</h3>
        <p>
          Every traveler has different needs, and that’s why we offer a wide variety of accommodations to suit all tastes and budgets. From five-star luxury hotels 
          with world-class amenities to cozy bed-and-breakfasts tucked away in peaceful countryside settings, you’ll find a place that matches your style. Whether you’re 
          traveling solo, with family, or on a romantic getaway, our platform makes it easy to search and filter accommodations based on your specific requirements, 
          such as location, price range, amenities, and guest ratings. Each hotel is carefully curated to ensure quality and comfort, allowing you to enjoy your trip with confidence.
        </p>

        <h3>Seamless Booking Experience for Every Device</h3>
        <p>
          In today’s fast-paced world, convenience is key. We’ve designed our platform to offer a seamless booking experience across all devices—whether you’re browsing from 
          your smartphone, tablet, or desktop. With just a few clicks, you can explore accommodations, compare options, and secure your booking. We’ve also ensured that our platform 
          is easy to navigate, with clear filters, intuitive layouts, and all the information you need to make informed decisions. And with our secure payment system, 
          you can rest assured that your transactions are safe and hassle-free.
        </p>

       

        <h3>Travel with Confidence: Quality and Sustainability Combined</h3>
        <p>
          We believe that every journey should be a source of joy, not stress. That’s why we work hard to ensure that every accommodation on FindHotelToday meets the highest standards 
          of quality, service, and comfort. Our team handpicks hotels and stays that consistently receive top ratings from guests, so you can book with confidence. Moreover, 
          we are committed to promoting sustainable travel by partnering with eco-conscious hotels and accommodations that prioritize environmental responsibility. This way, 
          you can make choices that align with your values while enjoying a comfortable stay.
        </p>

        <h3>Exclusive Benefits for FindHotelToday Members</h3>
        <p>
          At FindHotelToday, loyalty pays off. When you become a member, you unlock a world of exclusive perks designed to make your trips even more enjoyable. From discounted rates 
          and special offers to personalized travel tips, our members receive benefits that enhance every journey. You’ll also have access to member-only deals, such as early check-ins, 
          late check-outs, and complimentary upgrades at select properties. Our loyalty program rewards you for every stay, helping you save on future bookings while enjoying added value.
        </p>

        <h3>Our Commitment to Your Satisfaction</h3>
        <p>
          Your satisfaction is at the heart of everything we do. We take pride in delivering a smooth, enjoyable experience from the moment you start browsing for your 
          next destination. With our customer-first approach, we continuously strive to exceed your expectations. If there’s ever anything that doesn’t meet your needs, our team is 
          always ready to go the extra mile to resolve any issues. We believe that every traveler deserves the best, and we’re here to make sure you have a trip to remember, every time.
        </p>

        <h3>Stay Connected: FindHotelToday’s Community</h3>
        <p>
        Join the FindHotelToday community to stay up to date on the cities latest travel trends, special deals and must-visit places. Follow us on social media, 
          subscribe to our newsletter, and check out our blog for travel inspiration, guides, and insider tips. We love hearing from our travelers, so feel free to share your 
          experiences and reviews—your feedback helps us make FindHotelToday even better!
        </p>
        
      </div>
    </section>
  );
};


export default AboutUs;
