import React, { useState } from "react";
import styles  from "./book.module.css";

const SearchForm = () => {
  const [destination, setDestination] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    const baseUrl = "https://www.booking.com/searchresults.html";
    
    // Format dates to match Booking.com's expected format (yyyy-mm-dd)
    const formattedCheckIn = checkIn.split('-').join('');
    const formattedCheckOut = checkOut.split('-').join('');
    
    const params = new URLSearchParams({
      ss: destination,                    // Search string (destination)
      sb_price_type: 'total',            // Show total price
      group_adults: '1',                 // Default number of adults
      no_rooms: '1',                     // Default number of rooms
      group_children: '0',               // Default number of children
      from: checkIn,                     // Check-in date
      to: checkOut,                      // Check-out date
      selected_currency: 'USD',          // Currency
      search_type: 'city',              // Search type
      order: 'price',                    // Sort order
      nflt: '',                         // No filters by default
      top_currency: '1',                // Show prices in top currency
      rows: '15'  
    });

    window.open(`${baseUrl}?${params.toString()}`, "_blank");
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSearch} className={styles.bookForm}>
        <input
          type="text"
          placeholder="Where are you headed?"
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
          className={styles.destination}
          required
        />

        <input
          type="date"
          value={checkIn}
          onChange={(e) => setCheckIn(e.target.value)}
          className={styles.date}
          required
        />

        <input
          type="date"
          value={checkOut}
          onChange={(e) => setCheckOut(e.target.value)}
          className={styles.date}
          required
        />

        <button type="submit" className={styles.button}>
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchForm;
