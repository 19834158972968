

import React from "react";
import './terms.module.css'
import styles from './terms.module.css'
const TermsOfService = () => {
  return (
    <div className={styles.terms}>
      <h1 >TERMS OF SERVICE</h1>
      <p>
        Hello and welcome! Thank you for reading these Terms of Service
        (hereinafter referred to as “Terms”).
      </p>

      <p>
        Your use of our service is subject to your acceptance of these Terms. To
        book a Travel Service, you must also accept these Terms. If you do not
        accept these Terms, please do not use our Service or book a Travel
        Service.
      </p>
      <p>
        We may change these Terms at any time, and your continued use of our
        Service following such changes is subject to your acceptance of the
        updated Terms. We recommend saving or printing a copy of these Terms.
      </p>

      <h2  >Definitions</h2>
     
        In these Terms:
        <ul>
          <li>
            “Partners” means any affiliated, co-branded, or linked website
            providing content or services from our Group Companies.
          </li>
          <li>
            “Service” means the provision of our websites, applications, and
            online tools.
          </li>
          <li>
            “Travel Provider” means the travel supplier offering Travel Services
            to you via our Service.
          </li>
          <li>
            “Travel Services” means the travel services provided to you via our
            Service by the relevant Travel Providers, such as accommodations.
          </li>
          <li>
            “you” refers to the person using our Service or booking a travel
            service through our Service.
          </li>
        </ul>
     

      <h2  >Section 1: Rules and Restrictions</h2>
      <p>
        In addition to these Terms, other terms and conditions provided by
        Travel Providers (e.g., property terms and conditions) apply to your
        booking (“Rules and Restrictions”).
      </p>
      <p>
        To make a booking, you must accept the Rules and Restrictions of the
        Travel Provider you choose (such as amounts payable, refundability,
        penalties, availability restrictions, and the use of fees or services).
        The relevant Rules and Restrictions will be provided to you before you
        make a booking and are incorporated into these Terms by reference.
      </p>
      {/* <p>
        If you violate a Travel Provider's Rules and Restrictions, your booking
        may be canceled, and access to the relevant Travel Service may be
        denied. You may also lose any money paid, and we or the Travel Provider
        may charge your account for any costs incurred as a result of such
        violation.
      </p> */}
      <p>
        Travel Providers may be individuals operating consumer-to-consumer (C2C)
        or professionals operating business-to-consumer (B2C). We label listings
        as “private host” or “private supplier” when the Travel Provider
        indicates they are acting as an individual (non-professional). If you
        enter into an agreement with an individual, please note that consumer
        law may not apply to your contract with the Travel Provider. The Travel
        Provider is responsible for indicating whether they are acting as a
        consumer or business and for any representations made about their
        status.
      </p>

      <h2  >Section 2: Using Our Service</h2>
      <p>
        <b>    Our Rules</b>
    
        <br />
        We offer our Service to help you find information about travel services
        and book these travel services. Our Service is not offered for any other
        purpose.
      </p>
      <p>
        Our Service aims to present many travel options. However, our Service
        does not provide a comprehensive list of all travel options available in
        a particular destination or in response to a specific search query.
        Additional travel options may be available for booking through other
        distribution channels.
      </p>
  
        You agree to:
        <ul>
          <li>Use our Service only for personal and non-commercial purposes</li>
          <li>
            Be at least 18 years old and have the legal capacity to enter into a
            contract
          </li>
          <li>Use our Service in compliance with the law and these Terms</li>
          <li>
            Ensure that all information you provide is accurate, current, and
            complete
          </li>
          <li>If you have an account:</li>
          <ul>
            <li>Protect your account information</li>
            <li>
              You are responsible for any use of your account by you or others
            </li>
          </ul>
          <li>If you make bookings on behalf of others:</li>
          <ul>
            <li>Act with their permission</li>
            <li>
              Inform them of the booking terms (including Rules and
              Restrictions) and ensure they accept them
            </li>
            <li>
              Be responsible for paying all amounts due, making
              changes/cancellations, and handling any other matters related to
              the booking
            </li>
          </ul>
        </ul>
  
     
        You also agree not to:
        <ul>
          <li>Make any false or fraudulent bookings</li>
          <li>
            Monitor or copy content from our Service using any robot, spider,
            scraper, or other automated means or manual process
          </li>
          <li>
            Violate any restrictions in any robot exclusion headers on our
            Service or bypass or circumvent measures preventing or restricting
            access to our Service
          </li>
          <li>
            Take any action that may impose an unreasonable or
            disproportionately large load on our infrastructure
          </li>
          <li>Deep-link to any part of our Service</li>
          <li>
            Frame, mirror, or otherwise incorporate any part of our Service into
            any other website
          </li>
        </ul>
     
      <p>
       <b>Access</b> 
        <br />
        We may, at our discretion, deny access to our Service to any person at
        any time for any reason. We may also make improvements and changes to
        our Service.
      </p>
      <p>
       <b>Account</b> 
        <br />
        To create an account, you must be at least 18 years old and follow the
        account creation instructions provided through our Service.
        <br />
        An account you create through our Service also provides access to other
        sites, applications, tools, and services offered by our Group Companies
        using the same account credentials . When using your findHotelTodayGroup Account, please note that the
        terms of service applicable to the sites, applications, tools, and
        services you use will govern your use.
        <br />
        To delete your account, log in to your account on our Service and follow
        the applicable account deletion process. For more information about your
        privacy rights (e.g., deletion or access), please refer to our Privacy
        Notice.
      </p>

      <h2 
       >Section 3: Confirming a Booking</h2>
      <p>
        Your Booking Confirmation contains the key elements of your booking,
        such as the description and price of the booked Travel Service(s).
      </p>
      <p>
        We will send your Booking Confirmation and related travel documents to
        the email address you provided at the time of booking. If you do not
        receive your Booking Confirmation within 24 hours of making a booking,
        please contact us.
      </p>
      <p>
       If the error should have been apparent to you,
        we are not obligated to provide the Travel Services at the incorrect
        (lower) price, even after the Booking Confirmation has been issued.
      </p>
      <p>
      <b>Taxes</b>  
        <br />
        Prices shown through our Service may include taxes or tax recovery
        charges. Such taxes or tax recovery charges may include amounts related
        to value-added tax, goods and services tax, sales tax, occupancy tax,
        and other similar taxes.
      </p>
      <p>
        Taxes or tax recovery charges are usually calculated or estimated based
        on the prices shown through our Service before discounts (including
        those financed by us), coupons, and loyalty rewards are applied.
        Otherwise, these discounts, coupons, and loyalty rewards are not
        considered price reductions for the purpose of calculating or estimating
        taxes in the relevant jurisdiction for the booking.
      </p>
      <p>
        In some jurisdictions, you may be responsible for paying local taxes
        (such as city taxes or tourist taxes) imposed by local tax authorities.
        Our Group Companies or the Travel Provider may collect these local taxes
        from you. If there are any such taxes reported to us by the Travel
        Provider, our Group Companies will inform you of any local taxes due
        before completing your booking.
      </p>
      <p>
        The amount of local taxes may vary between the booking date and the
        accommodation date. If taxes have changed based on your stay date, you
        may be required to pay the higher rate.
      </p>

     
        If we determine that a booking or account is associated with fraud,
        abuse, a government-sanctioned person or entity, or suspicious activity,
        we may take the following actions:
        <ul>
          <li>
            Cancel all bookings associated with your name, email address, or
            account
          </li>
          <li>Close the relevant accounts, and</li>
          <li>
            Initiate legal proceedings, including legal actions to hold you
            responsible for losses.
          </li>
        </ul>
    
     

      <h2  >Section 5: Cancelling or Modifying a Booking</h2>
      <p>
      <b> Your Cancellation or Modification</b> 
        <br />
        To cancel or modify a booking (including changing travel dates,
        destination, departure point, property, or mode of transportation),
        contact us.
      </p>
      <p>
        Unless permitted under the Rules and Restrictions of the relevant Travel
        Provider (which will be provided to you before booking), you do not
        automatically have the right to cancel or modify a booking.
      </p>
      <p>
        Travel Providers may charge you fees to cancel (in whole or in part) or
        modify a booking. Such fees are specified in the Rules and Restrictions.
        You agree to pay any fees incurred. For modifications, the price of your
        new arrangements will be determined based on the price applicable on the
        date you request the modification. This price may differ from the price
        when you first booked the Travel Services. Prices generally increase as
        the modification date approaches.
      </p>
     
        Be sure to read the relevant Rules and Restrictions to understand the
        terms applicable to your booking. For example:
        <ul>
          <li>
            If you book a property stay and do not cancel or modify your booking
            before the relevant cancellation policy period expires, you may be
            subject to cancellation or modification fees as specified in the
            relevant Rules and Restrictions
          </li>
          <li>
            Some properties do not allow cancellations or modifications after
            booking
          </li>
          <li>
            If you make a Book Now, Pay Later reservation and do not show up or
            cancel the reservation, the property may charge a no-show or
            cancellation fee as specified in the relevant Rules and
            Restrictions, and you may be liable to pay the property's no-show or
            cancellation fee
          </li>
          <li>
            If you do not show up or do not use some or all of the Travel
            Services you booked, you may be entitled to a refund only according
            to the relevant Rules and Restrictions.
          </li>
        </ul>
     
      <p>
        If you wish to cancel or modify any part of a booking and it is
        permitted by the relevant Travel Provider, we may also charge you an
        administrative fee in addition to any fees applied by the Travel
        Provider. If such an administrative fee is applicable, we will inform
        you before proceeding with the modification/cancellation.
      </p>
      <p>
     <b> Other Cancellations or Modifications</b>  
        <br />
        If full payment for a booking or the cancellation/modification fee or
        charge related to the booking is not received, we (and the relevant
        Travel Provider) may cancel your booking.
      </p>
      <p>
        For various reasons (e.g., a property may be overbooked or closed due to
        a hurricane, etc.), a booking may be canceled or modified by the Travel
        Provider or us. In such cases, we will make reasonable efforts to notify
        you as soon as possible and offer alternative options/assistance or
        refunds.
      </p>

      <h2  >Section 6: Specific Conditions for Travel Services</h2>
      <p>
        This Section provides details about the conditions applicable to
        specific Travel Services offered by Travel Providers. These details are
        not exhaustive and do not replace the relevant Rules and Restrictions
        provided to you before booking.
      </p>
      <p>
        Each Travel Service is subject to the relevant Rules and Restrictions of
        the Travel Provider. Please also read this Section, as it will apply to
        your booking. If there is any inconsistency between this Section and the
        relevant Rules and Restrictions, the relevant Rules and Restrictions
        will prevail.
      </p>

      <b>A. Accommodations</b>
      <p>
        Booking.com service may offer you the option to Pay Now or Pay Later. Room rates
        (including applicable taxes and fees) are displayed to you under Pay Now
        and Pay Later payment options through our Service. Taxes and fees may
        vary depending on the payment option you choose. Tax rates and exchange
        rates may change between your booking and accommodation date.
      </p>
      <p>
        <strong>Pay Now</strong>
        <br />
        If you choose the Pay Now payment option, the relevant company (as
        specified in Section 4 (Payment)) will usually charge your payment
        method for the booking amount at the time of booking.
      </p>
      <p>
        <strong>Pay Later</strong>
        <br />
        If you choose the Pay Later payment option, the Travel Provider will
        usually charge your payment method in the local currency during your
        stay or as indicated to you during the booking process.
      </p>
      <p>
        <strong>Deposit</strong>
        <br />
        Some Travel Providers may require a payment card or cash deposit upon
        check-in to cover any additional charges incurred during your stay. This
        deposit is unrelated to any payments made to the relevant company (as
        specified in Section 4 (Payment)) for your booking.
      </p>
      <p>
        <strong>First Night No-Show</strong>
        <br />
        If you do not show up for the first night of your accommodation booking
        but plan to check in for subsequent nights, please contact us before
        your original check-in date. Failure to confirm this may result in the
        cancellation of your entire booking. Refunds for no-shows will only be
        provided according to the property's relevant Rules and Restrictions.
      </p>
      <p>
        <strong>Group Bookings</strong>
        <br />
        You may not book more than eight rooms through our Service at the same
        property for the same dates. If you book more than eight rooms in
        separate reservations, we may cancel your bookings. We may also charge a
        cancellation fee, and any non-refundable deposit you paid may be
        forfeited. If you wish to book more than eight rooms, please use the
        “Groups and Meetings” section of our Service. You may be required to
        sign a written contract or pay a non-refundable deposit.
      </p>
      <p>
        <strong>Ratings</strong>
        <br />
        The ratings displayed through our Service indicate the level and type of
        features you can expect at the property (where applicable) and may be
        provided by local and national star rating organizations. These ratings
        may differ from the standards in your own country. The ratings displayed
        on the site are not guaranteed for any specific feature or amenity.
        Additional information is available in the “Overview” or “Amenities”
        section of the property details page. These guidelines are subject to
        change, and our Group Companies and Partners cannot guarantee the
        accuracy of any specific rating displayed through our Service from time
        to time.
      </p>
      <p>
        <strong>Meals</strong>
        <br />
        If meals are included as part of your accommodation booking, the number
        of meals provided is based on the number of nights of your stay. Full
        board usually includes breakfast, lunch, and dinner. Half board usually
        includes breakfast and lunch or dinner. No refunds are provided if one
        or more meals are not consumed.
      </p>

      <h3>B. Vrbo Vacation Rentals</h3>
      <p>
        If you book a vacation rental property distributed by Vrbo or its brands
        (Stayz, Bookabach, FeWo Direkt, and Abritel) through our Service, it is
        referred to as a “Vrbo Vacation Rental.” The Vrbo terms and conditions
        provided at the time of payment and booking of the Vrbo Vacation Rental
        apply.
      </p>

      <h3>C. Linked Travel Arrangements</h3>
      <p>
        A “Linked Travel Arrangement” has the same meaning as defined in the
        European Parliament and Council Directive 2015/2302 on package travel
        and linked travel arrangements, as applicable under national law
        (“Directive (EU) 2015/2302”).
      </p>
      <p>
        In certain situations and depending on the Travel Services offered from
        time to time, a Linked Travel Arrangement may arise when you decide to
        book through our Service. When this possibility arises, you will be
        asked to read the following important information.
      </p>
      <p>
        If you book additional Travel Services for your trip or holiday via our
        Service after selecting and paying for one Travel Service, you will not
        benefit from the rights applying to packages under Directive (EU)
        2015/2302. Therefore, we will not be responsible for the proper
        performance of those additional Travel Services. In case of problems,
        please contact the relevant Travel Provider.
      </p>
      <p>
        However, if you book an additional Travel Service (such as a car rental)
        via a link on the confirmation page or Booking Confirmation within 24
        hours after receiving confirmation of a property booking, these travel
        services will become part of a Linked Travel Arrangement. In this case,
        we have protection in place to refund your payments for services not
        performed due to our insolvency, as required by EU law. Please note that
        if payments are made directly to the relevant Travel Provider, we will
        not provide any protection to refund you in case of the Travel
        Provider's insolvency.
      </p>
     

      <h2  >Section 7: International Travel</h2>
      <p>
        While most travel goes smoothly, some destinations may involve more risk
        than others. Before booking international travel, you should review the
        travel advisories issued by the relevant governments. You should also
        monitor these travel advisories during your trip and before your return
        journey and take necessary precautions to avoid and minimize potential
        disruptions.
      </p>
      <p>
        Warnings, entry requirements, and travel advisory information on certain
        international destinations can be found here. You are responsible for
        being aware of and complying with any applicable recommendations,
        restrictions, and entry requirements.
      </p>
    
        <strong>Health</strong>
        <br />
        You should check recommended vaccinations/immunizations, as these can
        change. Consult your doctor before your trip. Your responsibilities
        include:
        <ul>
          <li>Meeting all health entry requirements</li>
          <li>Obtaining relevant/necessary vaccinations/immunizations</li>
          <li>Taking any recommended medications, and</li>
          <li>Complying with all medical advice related to your trip.</li>
        </ul>
    
      <p>
        Online medical advice for travelers can be found here. Otherwise,
        consult your doctor for medical advice related to your journey.
      </p>
      <p>
        <strong>Passports and Visas</strong>
        <br />
        You should contact the relevant Embassy or Consulate for passport and
        visa information. Requirements may change, so check the most current
        information and allow adequate time for all necessary applications
        before booking and traveling.
      </p>
      <p>
        Our Group Companies are not liable for your refusal to enter any country
        or board any flight or ship (if applicable). This includes all aircraft
        or ship stops (if applicable), even if you do not leave the plane or
        airport or the ship.
      </p>
      <p>Passport: You must have a valid passport for all holidays.</p>
      <p>
        Some overseas countries have immigration requirements that require your
        passport to be valid for a minimum period after entry into that country,
        typically six months. If your passport is in its final year, check your
        destination's requirements before finalizing your travel plans.
      </p>
      <p>
        The name on the passport must match the name on the ticket; otherwise,
        you may not be able to travel and your insurance may be invalid. If any
        party member's name changes (e.g., due to marriage, etc.) after booking
        a Travel Service but before traveling, you must inform us.
      </p>
      <p>For more information, visit here.</p>
      <p>
        Visa: For visa requirements, contact the Embassy or Consulate of the
        country you plan to visit and, if applicable, the Embassy or Consulate
        of the country to which you intend to return if you are not a citizen.
      </p>
      <p>
        Some governments require airlines to provide personal information about
        all passengers on their aircraft. These data will be collected at
        check-in at the airport or, in some cases, when you make your booking.
        For any questions about this, contact the relevant airline you will be
        traveling with.
      </p>
      <p>
        Our Group Companies do not represent or warrant that travel to
        international destinations is advisable or without risk and are not
        liable for any damages or losses resulting from travel to such
        destinations.
      </p>

      <h2  >Section 8: Liability</h2>
      <p>
        <strong>Liability for Travel Services</strong>
        <br />
        Travel Providers provide the Travel Services to you.
      </p>
     
        Where findHotelTodayTravel is the Travel Provider, to the maximum extent
        permitted by law and subject to the exclusions and limitations in these
        Terms or the relevant Rules and Restrictions, findHotelTodayTravel will be
        liable only for direct:
        <ul>
          <li>
            damages that are reasonably foreseeable by both you and 
            doamin name,
          </li>
          <li>actually suffered or incurred by you, and</li>
          <li>
            directly attributable to findHotelTodayTravel's actions in providing the
            Travel Services,
          </li>
        </ul>
        and findHotelTodayTravel's liability in relation to any Travel Service will,
        in any event, be limited to the cost you paid to findHotelTodayTravel for the
        relevant Travel Service.
     
      <p>
        The liability of other Travel Providers to you will be as set out in the
        relevant Rules and Restrictions. To the maximum extent permitted by law,
        where findHotelTodayTravel is not the Travel Provider, findHotelTodayTravel will not
        be liable to you for any Travel Services provided by other Travel
        Providers.
      </p>
      <p>
        <strong>Our Liability</strong>
        <br />
        We operate our Service, and the Travel Providers provide the Travel
        Services to you.
      </p>
      
        To the maximum extent permitted by law, we and our Partners will not be
        liable for:
        <ul>
          <li>Any Travel Service provided to you by Travel Providers</li>
          <li>
            The acts, errors, omissions, representations, warranties, or
            negligence of such Travel Providers, or
          </li>
          <li>
            Any personal injuries, death, property damage, or other damages or
            expenses resulting from the above.
          </li>
        </ul>
     
      <p>
        Travel Providers provide the information describing the Travel Services
        to you. This information includes details of the Travel Service, photos,
        rates, and relevant Rules and Restrictions. We display this information
        through our Service. Travel Providers are responsible for ensuring the
        accuracy, completeness, and currency of this information. Our Group
        Companies and Partners do not guarantee the accuracy of such
        information, except where we directly cause inaccuracies (this also
        includes ratings provided for guidance and not as an official rating).
        Our Group Companies and Partners do not guarantee the availability of
        specific Travel Services.
      </p>
      <p>
        Photos and illustrations on our Service are provided solely as a guide
        to show the level and type of accommodation.
      </p>
      <p>
        For more information about the content displayed on our Service, please
        refer to the Content Guidelines.
      </p>
     
        To the maximum extent permitted by law, and except as explicitly set out
        in these Terms:
        <ul>
          <li>
            All information, software, or Travel Services displayed through our
            Service are provided without any warranty or condition. This
            includes implied warranties and conditions of satisfactory quality,
            merchantability, fitness for a particular purpose, title, or
            non-infringement, but is not limited to them, and
          </li>
          <li>
            Our Group Companies and Partners disclaim all such warranties and
            conditions.
          </li>
        </ul>
    
      <p>
        The display of Travel Services through our Service does not constitute
        an endorsement or recommendation by our Group Companies or Partners of
        such Travel Services. To the maximum extent permitted by law, our Group
        Companies and Partners disclaim all warranties and conditions that our
        Service, its servers, or any email sent from us or our Partners are free
        of viruses or other harmful components.
      </p>
     
        To the maximum extent permitted by law and subject to the limitations in
        these Terms, neither our Group Companies nor our Partners will be liable
        for any indirect, punitive, special, incidental, or consequential
        damages or losses arising out of or relating to:
        <ul>
          <li>The Travel Services,</li>
          <li>Your use of our Service,</li>
          <li>Any delay or inability to use our Service, or</li>
          <li>Your use of links from our Service,</li>
        </ul>
        whether based on negligence, contract, tort, strict liability, consumer
        protection statutes, or any other legal theory, and even if our Group
        Companies and Partners have been advised of the possibility of such
        damages.
   
     
        If we are found liable for any liability under these Terms or for any
        loss or damage under these Terms, then to the maximum extent permitted
        by law, we will only be liable for direct:
        <ul>
          <li>damages that are reasonably foreseeable by both you and us,</li>
          <li>actually suffered or incurred by you, and</li>
          <li>directly attributable to our actions,</li>
        </ul>
        and our liability in total will not exceed the greater of (a) the cost
        you paid for the relevant Travel Service or (b) one hundred dollars
        (100.00 $) or the equivalent amount in your local currency.
    
      <p>
        This limitation of liability reflects the allocation of risks between
        you and us. The limitations specified in this Section will survive even
        if any limited remedy specified in these Terms is found to have failed
        in its essential purpose. The limitations of liability in these Terms
        will inure to the benefit of our Group Companies and Partners. In the
        event of force majeure, interruption of communication means, or a strike
        (airlines, properties, air traffic controllers, as applicable), the
        obligations of these Terms will be suspended. In such a case, the party
        affected by the force majeure will not be liable for failing to fulfill
        these obligations. Consumers have certain legal rights. The exclusions
        and limitations in these Terms only apply to the extent permitted by
        law. Nothing in these Terms limits or excludes our liability for fraud
        or personal injury or death resulting from our actions or omissions.
      </p>
      <b >Indemnification</b>
    <p>  You agree to defend and indemnify our Group Companies and Partners and
        any of their officers, directors, employees, and agents from any claims,
        causes of action, demands, recoveries, losses, damages, fines,
        penalties, or other costs or expenses ("Losses"), including reasonable
        legal and accounting fees, brought by:</p>
      
        <ul>
          <li>
            Your violation of these Terms or the documents referenced herein
          </li>
          <li>Your violation of any law or the rights of a third party, or</li>
          <li>Your use of our Service,</li>
          to the extent such Losses are not directly caused by the actions of
          our Group Companies or Partners.
        </ul>
   

      <h2 className="my-6 text-4xl" >Section 9: Reviews, Comments, and Photos</h2>
    
        By submitting content to our Service via email, postings, or other
        means, including property reviews, photos, videos, questions, comments,
        suggestions, ideas, or other submissions (collectively, "Submissions"):
        <ul>
          <li>
            You confirm that all your Submissions are your original creation and
            that you have all necessary rights to grant the rights to use your
            Submissions as specified in these Terms, and you will maintain these
            rights, and
          </li>
          <li>
            You grant our Group Companies and Partners a non-exclusive,
            royalty-free, perpetual, transferable, irrevocable, and fully
            sublicensable, multi-level right, to the extent permitted by law, to
            use, reproduce, modify, adapt, translate, distribute, create
            derivative works from, and publicly display and perform your
            Submissions worldwide in any media, now known or hereafter
            developed.
          </li>
        </ul>
    
      <p>
        You also acknowledge and agree that our Group Companies and Partners
        have the right, at their discretion, to attribute your Submissions to a
        name (e.g., listing the review author's name and hometown). These
        Submissions may also be shared with Travel Providers.
      </p>
      <p>
        You further agree that our Group Companies have the right to pursue
        legal action against any person or entity that violates your rights or
        our Group Companies' rights in the Submissions.
      </p>
      <p>Submissions are not confidential or proprietary.</p>
      <p>
        To the extent possible, you expressly waive any "moral rights"
        (including rights of attribution or integrity) you may have in your
        Submissions. You agree not to object to the publication, use,
        modification, deletion, or exploitation of your Submissions by our Group
        Companies, Partners, or other sublicensees.
      </p>
     
        You are fully responsible for the content of your Submissions. You agree
        not to submit to our Service any content:
        <ul>
          <li>
            That is illegal, threatening, defamatory, obscene, pornographic, or
            otherwise violates the privacy rights or any law
          </li>
          <li>
            That is commercial in nature (such as solicitations for funds,
            advertising, or marketing any goods or services)
          </li>
          <li>
            That infringes, misappropriates, or violates any third party's
            copyright, trademark, patent, or other proprietary rights, or
          </li>
          <li>
            That is objectionable concerning public order, public morals, public
            safety, or national harmony.
          </li>
        </ul>
     
      <p>
        You will be solely liable for any damages resulting from any violation
        of these rules or other harm resulting from your Submissions to our
        Service.
      </p>
      <p>
        We may use our rights related to Submissions (e.g., use, publish,
        display, delete, etc.) without notice to you.
      </p>
      <p>
        If you submit multiple reviews for the same property, only your latest
        Submission may be used.
      </p>
      <p>All Submissions are subject to our Content Guidelines.</p>
      <p>
        We do not claim ownership or endorsement of or association with any
        Submission you make.
      </p>
      <p>For more information, please refer to the Content Guidelines.</p>

      <h2  >Section 10: Intellectual Property Policy and Notices</h2>
      <p>
        <strong>Copyright and Trademark Notices</strong>
        <br />
        All content on our Service is ©2024 findHotelToday.com. All rights reserved.  We
        are not responsible for the content of websites operated by other
        parties.
      </p>
      <p>
        The Google® Translate tool has been provided through our Service to
        allow you to translate content such as user-generated reviews. The
        Google® Translate tool uses an automated process for translations,
        which may result in inaccuracies. Your use of the Google® Translate
        tool is at your own risk. We make no promises, assurances, or guarantees
        about the accuracy or completeness of translations provided by the
        Google® Translate tool.
      </p>
      <p>
        Our Service may contain links to websites operated by other parties.
        These links are provided for your reference only. We do not control
        these websites and are not responsible for their content or use. The
        inclusion of links does not imply any endorsement of the material on
        these websites or any association with their operators.
      </p>
      <p>
      If you become aware of any trademark infringement, please notify us by sending an email.
      </p>

      <h2  >Intellectual Property Infringement Policy and Complaints</h2>
      <p>
        We respect the intellectual property rights of others and expect our
        suppliers, partners, and users (collectively, "Users") to do the same.
        We have a policy that prohibits Users from posting materials that
        infringe on the copyright, trademark, or other intellectual property
        rights of others, and we will terminate the accounts of repeat
        infringers in appropriate circumstances. Requirements and instructions
        for copyright and trademark complaints can be found in the “Intellectual
        Property Infringement Complaints and Forms” section.
      </p>

      <h2  >Patent Notices</h2>
      <p>
        One or more patents may apply to our Service and the features and
        services accessible via our Service. Some parts of our Service may
        operate under license of one or more patents. Other patents are pending.
      </p>

      <h2 >Section 11: Software Available on Our Service</h2>
      <p>
        <strong>Software</strong>
        <br />
        Any software that is made available to download from our Service or
        through a mobile application store ("Software") is the copyrighted work
        of our Group Companies or relevant suppliers. Your use of the Software
        is governed by the end-user license agreement ("License Agreement") that
        accompanies or is included with the Software. You must accept the
        License Agreement before installing, downloading, or using any Software.
      </p>
      <p>
        For any Software not accompanied by a License Agreement, we grant you a
        limited, personal, non-exclusive, non-transferable, and
        non-sublicensable license to download, install, and use the Software
        solely for the purpose of using our Service and not for any other
        purpose, subject to these Terms. The Software is provided to you free of
        charge.
      </p>
      <p>
        All Software (including all HTML code and Active X controls, etc.)
        contained on our Service is owned by our Group Companies, Partners, or
        relevant suppliers. All Software is protected by copyright laws and
        international treaty provisions. Reproduction or redistribution of the
        Software is prohibited and may result in severe civil and criminal
        penalties. Violators will be prosecuted to the maximum extent possible.
      </p>
      <p>
        Without limiting the foregoing, copying or reproducing the Software to
        any other server or location for further reproduction or redistribution
        is expressly prohibited. The Software is warranted, if at all, only
        according to the terms of the License Agreement.
      </p>

      <h2  >Map Terms</h2>
      <p>
        Your use of maps on our Service is governed by the Google Terms of
        Service, Google Acceptable Use Policy, Google Legal Notices, and Google
        Privacy Policy, and the Microsoft Terms of Service and Microsoft Privacy
        Policy. Google and Microsoft reserve the right to change their Terms of
        Service and Privacy Policies at any time, in their sole discretion.
      </p>
      <p>
        Geographic data used in mapping is owned by OpenStreetMap contributors
        and is available under the Open Database License (ODbL).
      </p>

      <h2  >Section 12: Your Privacy and Personal Information</h2>
      <p>
        We are committed to the confidentiality, privacy, and security of the
        personal information entrusted to us.
        <br />
        Please review our Privacy Notice, which also governs your use of our
        Service and is incorporated into these Terms by reference, to understand
        our practices.
      </p>

      <h2  >Section 13: Rewards Program</h2>
      <p>
        We have a free loyalty program for our travelers. For more information
        about our program and benefits, please see the applicable terms and
        conditions. These are incorporated into these Terms by reference.
      </p>

      <h2  >Section 14: Contact and Complaints</h2>
      <p>
        <strong>Traveler Support and Handling of Complaints</strong>
        <br />
        We are here to help if you have any questions or complaints about your
        booking.
      </p>
      <p>
        Visit our Support page to get answers to commonly asked questions or
        contact us via the chat function.
      </p>
      <p>
        If you experience any issues with your booked Travel Service, we will
        assist you in resolving the problem if you make a complaint or have a
        right to compensation from the Travel Provider.
      </p>
      <p>
        Whenever possible, please report any issues with the Travel Service you
        booked to us or the relevant Travel Provider before or during your trip.
        This allows us to address the issue promptly and mitigate any damage you
        may suffer. If you do not report the problem during or before your trip,
        you may miss the opportunity to have the issue reviewed and rectified
        while on vacation. This could impact your rights under these Terms,
        potentially leading to a reduction in compensation, potentially to zero.
      </p>
      <p>
        If you encounter any issues with the booked Travel Service (e.g., the
        problem occurred during the inbound journey), please contact us or the
        relevant Travel Provider as soon as possible after your trip. It is
        recommended to submit your complaints within 30 days after your trip.
      </p>
      <p>
        <strong>
          European Commission's Online Dispute Resolution Platform
        </strong>
        <br />
        The European Commission's Online Dispute Resolution Platform is
        available at https://wwww.findHotelToday.com.
      </p>
      <p>
        <strong>Illegal Content and Security Concerns</strong>
        <br />
        If you believe content on our Service is illegal, please email us.
      </p>
      <p>
        For more information on what to do if you suspect content on our Service
        is illegal, please refer to the Content Guidelines.
      </p>

      <h2  >Section 15: General</h2>
      <p>
        <strong>Governing Law and Jurisdiction</strong>
        <br />
        These Terms are governed by Irish law. You agree to the exclusive
        jurisdiction and venue of the Irish courts for all disputes arising out
        of or relating to the use of our Service or these Terms. Use of our
        Service is unauthorized in any jurisdiction that does not give effect to
        all provisions of these Terms, including this paragraph.
      </p>
      <p>
        <strong>Insurance</strong>
        <br />
        Unless otherwise specified, the prices displayed do not include travel
        insurance. It is recommended that you take out travel insurance, which
        covers certain risks, such as cancellation in specific circumstances and
        evacuation costs in the event of an accident or illness. It is your
        responsibility to ensure that the insurance policy you obtain adequately
        covers your needs. Travel insurance products may be displayed. If so,
        the insurance provider's details, relevant key information, and terms
        and conditions will be displayed on our Service.
      </p>
      <p>
        <strong>Non-Enforcement</strong>
        <br />
        Our failure to enforce or delay in enforcing any provision of these
        Terms will not constitute a waiver of our right to enforce that
        provision in the future.
      </p>
      <p>
        <strong>Severability</strong>
        <br />
        If any provision (or part of a provision) of these Terms is found by a
        court of competent jurisdiction or other competent authority to be
        invalid, illegal, or unenforceable, that provision (or part) will be
        treated as if it were not included in these Terms. In such a case, the
        validity and enforceability of the other provisions of these Terms will
        not be affected.
      </p>
      <p>
        <strong>Entire Agreement</strong>
        <br />
        These Terms constitute the entire agreement between you and us
        concerning our Service. They replace all prior or contemporaneous
        communications (electronic, oral, or written) about our Service.
      </p>
      <p>
        <strong>Assignment</strong>
        <br />
        We may assign, subcontract, or transfer any of our rights, duties, or
        obligations under these Terms, but you may not.
      </p>
      <p>
        <strong>Third-Party Rights</strong>
        <br />
        We do not intend for any part of these Terms to be enforceable by any
        person not a party to these Terms. No consent from any third party is
        required to waive, amend, or terminate any part of these Terms. These
        Terms do not confer any rights under any applicable law or regulation
        concerning any part of these Terms.
      </p>
      <p>
        <strong>Survival of Obligations</strong>
        <br />
        Any provision of these Terms that explicitly or by its nature imposes
        obligations beyond the expiration or termination of these Terms will
        survive the expiration or termination of these Terms.
      </p>
    </div>
  );
};

export default TermsOfService;
