import { useState, useEffect } from 'react';
import styles from './cookie.module.css'
const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
  };

  const handleShowPurposes = () => {
    // Implement logic to show detailed purposes
  };

  if (!isVisible) return null;

  return (
    <div className={styles.bar}>
    <div className={styles.content}>
      <div className={styles.text}>
        <p>
          We use store and/or access information on a device, such as unique IDs in cookies to process personal data. You may accept or manage your choices by clicking below or at any time in the privacy policy page. These choices will be signaled to our partners and will not affect browsing data.
        </p>
        <p>
          We and our partners process data to provide: Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, ad and content measurement, audience insights and product development.
        </p>
      </div>
      <div className={styles.buttons}>
        <button className={`${styles.button} ${styles.accept}`} onClick={handleAccept}>
          I Accept
        </button>
        <button className={`${styles.button} ${styles.reject}`}  onClick={handleReject}>
          Reject All
        </button>
      </div>
    </div>
  </div>
  );
};

export default CookieConsent;
