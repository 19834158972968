import React from "react";
import styles from "./home.module.css";
import SearchForm from "../../components/bookingForm/Book";

const Home = () => {
  return (
    <section className={styles.home}>
            <div className={styles.container}>
        <div className={styles.homeDescription}>
          <h1>Unforgettable Stays, Tailored for You.</h1>
          <p>
          At findHotelToday, we bring you the best hotels and vacation advice from around the world. Whether you're looking for luxury, adventure, or a cozy retreat, explore top-rated accommodations in every country. With personalized travel tips, exclusive deals, and a seamless booking experience, findHotelToday makes it easy to plan your next perfect getaway, no matter where your journey takes you
          </p>
        </div>
        <div className={styles.homeForm}>
          <div className={styles.homeFormBanner}>
            {/* <iframe
            title="Booking.com partner"
              src="https://www.booking.com/prelanding_product.html?responsive=true&languagecode=default&widget_id=2ac5c532-6fad-44b6-a02c-c0bacce52242&aid=7177374"
              scrolling="no"
              style={{
                border: "0px",
                position: "absolute",
                top: "0px",
                left: "100%",
                height: "100%",
                width: "100%",
                zIndex: 9999,
                transform: "translateX(-100%)",
                transition: "left 0.5s ease, transform 0.5s ease",
               
              }}
            ></iframe> */}
            <SearchForm/>
          </div>
        </div>
        </div>

    </section>
  );
};

export default Home;
