import React from "react";
import styles from "./card.module.css";


const Card = ({ data }) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_img}>
        <img src={data?.img} alt="img" />
      </div>
     
      <div className={styles.card_bottom}>
        <h5 className={styles.card_bottom_title}>{data.bottomTitle}</h5>
        <p className={styles.card_bottom_desc}>{data.bottomDesc}</p>
      </div>
    </div>
  );
};

export default Card;
