import React from "react";
import travel from "../../images/casinoImages/travel.webp";
import Card from "./Card/Card";
import { dataCasino } from "./data/index";

import styles from "./advice.module.css";

const Advice = () => {
  return (
    <div className={styles.casino} id="casino">
      <div className={styles.casino_text}>
        <h3 className={styles.casino_text_title}>
          Recommended Activities During International Travel
        </h3>
        <p className={styles.casino_text_desc}>
          To make the most of your trip, it’s important to immerse yourself in
          the local culture, try unique activities, and explore the highlights
          of your destination. Whether you're a history enthusiast, a foodie, or
          an outdoor adventurer, there’s always something exciting to do. Here’s
          a guide to help you plan enriching and memorable experiences during
          your travels abroad.{" "}
        </p>
      </div>
      <div className={styles.casino_wrapper}>
        <div className={styles.casino_wrapper_cards}>
          {dataCasino &&
            dataCasino.length > 0 &&
            dataCasino.map((item, key) => <Card data={item} key={item.id} />)}
        </div>
        <div className={styles.casino_wrapper_img}>
          <img src={travel} alt="casinoBanner" />
        </div>
        <div className={styles.casino_wrapper_cards}></div>
      </div>
    </div>
  );
};

export default Advice;
