import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"; // useLocation eklendi
import styles from "./header.module.css";
import logo from "../../images/logo.png";

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Route'ları izlemek için useLocation eklendi
  const [selectedValue, setSelectedValue] = useState("option1");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === "option1") {
      navigate("/");
    } else if (value === "option2") {
      navigate("/country/spain");
    } else if (value === "option3") {
      navigate("/country/indonesia");
    } else if (value === "option4") {
      navigate("/country/canada");
    } else if (value === "option5") {
      navigate("/country/ireland");
    } else if (value === "option6") {
      navigate("/country/greece");
    }
  };

  // Ana sayfaya dönüldüğünde varsayılan değeri geri yükle
  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedValue("option1"); // Ana sayfaya dönüldüğünde select'i varsayılan hale getir
    }
  }, [location.pathname]);

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to="/" className={styles.headerLogo}>
          <img src={logo} alt="logo" width={200} height={100} />
        </Link>
        <button className={styles.burgerMenu} onClick={toggleSidebar}>
          &#9776;
        </button>
        <nav
          className={`${styles.headerNav} ${isSidebarOpen ? styles.open : ""}`}
        >
          <button className={styles.closeButton} onClick={closeSidebar}>
            &times;
          </button>
          <ul className={styles.headerNavMenu}>
            <li className={styles.headerNavMenuItem} onClick={closeSidebar}>
              <Link to="/">Home</Link>
            </li>
            <li className={styles.headerNavMenuItem} onClick={closeSidebar}>
              <Link to="/about">About Us</Link>
            </li>
            <li className={styles.headerNavMenuItem} onClick={closeSidebar}>
              <label
                htmlFor="dropdown"
                style={{ position: "absolute", left: "-10000px" }}
              >
                Choose a Destination
              </label>
              <select
                style={{
                  background: "transparent",
                  border: "transparent",
                  outline: "transparent",
                }}
                id="dropdown"
                className={styles.headerNavMenuItem}
                value={selectedValue}
                onChange={handleChange}
              >
                <option value="option1" className={styles.headerNavMenuItem}>
                  Destinations
                </option>
                <option value="option2" className={styles.headerNavMenuItem}>
                  Spain
                </option>
                <option value="option3" className={styles.headerNavMenuItem}>
                  Indonesia
                </option>
                <option value="option4" className={styles.headerNavMenuItem}>
                  Canada
                </option>
                <option value="option5" className={styles.headerNavMenuItem}>
                  Ireland
                </option>
                <option value="option6" className={styles.headerNavMenuItem}>
                  Greece
                </option>
              </select>
            </li>

            <li className={styles.headerNavMenuItem} onClick={closeSidebar}>
              <Link to="/blogs">Blogs</Link>
            </li>
            <li className={styles.headerNavMenuItem} onClick={closeSidebar}>
              <Link to="/contact">Contacts</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
