import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./country.module.css";
import Hotel from "./hotels/Hotel";
import {
  countryData,
  spainHotels,
  canadaHotels,
  greeceHotels,
  indonesiaHotels,
  irelandHotels,
} from "./data/index";

export default function CountryPage() {
  const { country } = useParams();
  const [data, setData] = useState({
    title: "Loading...",
    description: "Please wait...",
    image: "",
    text: "",
  });
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    if (country) {
      const countryInfo = countryData[country.toLowerCase()] || {
        title: "Country not found",
        description: "We don't have data for this country.",
      };
      setData(countryInfo);

      if (country.toLowerCase() === "spain") {
        setHotels(spainHotels);
      } else if (country.toLowerCase() === "canada") {
        setHotels(canadaHotels);
      } else if (country.toLowerCase() === "ireland") {
        setHotels(irelandHotels);
      } else if (country.toLowerCase() === "indonesia") {
        setHotels(indonesiaHotels);
      } else if (country.toLowerCase() === "greece") {
        setHotels(greeceHotels);
      } else {
        setHotels([]);
      }
    }
  }, [country]);

  if (!country) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      
      <div className={styles.banner}>
        <div className={styles["image-wrapper"]}>
          <img src={data.image} alt={country} className={styles.image} />
        </div>

        <div className={styles.content}>
          <h1>{data.title}</h1>
          <p>{data.description}</p>
        </div>
      </div>
      <Hotel hotels={hotels} country={country} />

      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></div>

    </div>
  );
}
