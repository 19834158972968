
import React, { useState } from "react";
import styles from "./testimonials.module.css";

const testimonialsData = [
  {
    id: "content1",
    imgSrc: "https://randomuser.me/api/portraits/men/78.jpg",
    name: "Alex Johnson",
    title: "Italy, Florence",
    reviewContent:
      "This is my go-to website for hotel bookings. The layout is clean, and the search function is super intuitive. I’ve used it for both leisure and business trips, and I’m always impressed with how reliable the service is.",
  },
  {
    id: "content2",
    imgSrc: "https://randomuser.me/api/portraits/men/22.jpg",
    name: "Rocco Christian",
    title: "England, London",
    reviewContent:
      "I travel frequently for work, and this website makes finding the right hotel so much easier. I love the filters that let me sort by free Wi-Fi and proximity to my meeting locations. Booking is fast, and I’ve never had any issues!",
  },
];

const Testimonials = () => {
  const [activeContent, setActiveContent] = useState("content1");

  return (
    <section>
      <h1>Testimonials</h1>
      <div className={styles.cards}>
        <div className={styles.singleCard}>
          {testimonialsData.map((testimonial) => (
            <div
              key={testimonial.id}
              className={`${styles.card} ${
                activeContent === testimonial.id ? styles.active : ""
              }`}
              data-id={testimonial.id}
              onClick={() => setActiveContent(testimonial.id)}
            >
              <img src={testimonial.imgSrc} alt={testimonial.name} />
              <div>
                <h2>{testimonial.name}</h2>
                <p>{testimonial.title}</p>
              </div>
              <div className={styles.gradient}></div>
            </div>
          ))}
        </div>
        <div className={styles.content}>
          {testimonialsData.map((testimonial) => (
            <div
              key={testimonial.id}
              className={`${styles.contentBox} ${
                activeContent === testimonial.id ? styles.active : ""
              }`}
              id={testimonial.id}
            >
              <div className={styles.text}>
                <h2>{testimonial.reviewTitle}</h2>
                <p>{testimonial.reviewContent}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
